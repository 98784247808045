@use 'configs';
@use 'mixins';
@use 'sass:map';
@use 'utilities';

.form {
    &__title {
        color: configs.$formColorTitle;
        font: configs.$formFontMobileTitle;
        margin-block-end: map.get(configs.$spacing, s-12);

        @include mixins.breakpoint-from(desktop-small) {
            font: configs.$formFontDesktopTitle;
        }

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
            outline-offset: 4px;
        }
    }

    &__label,
    &__text {
        color: configs.$formColorDescription;
        font: configs.$formFontMobileText;

        @include mixins.breakpoint-from(desktop-small) {
            font: configs.$formFontDesktopText;
        }
    }

    &__text {
        margin-block-end: map.get(configs.$spacing, s-32);
    }

    &__element-text {
        color: configs.$formColorAdditionalInfo;
        font: configs.$formFontMobileAdditionalInfo;
        margin-block-start: map.get(configs.$spacing, s-4);

        @include mixins.breakpoint-from(desktop-small) {
            font: configs.$formFontDesktopAdditionalInfo;
        }
    }

    &__group-title {
        color: configs.$titleColorDefault;
        font: configs.$formFontMobileSubtitle;
        margin-block-end: map.get(configs.$spacing, s-8);

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
            outline-offset: 4px;
        }

        @include mixins.breakpoint-from(desktop-small) {
            font: configs.$formFontDesktopSubtitle;
        }
    }

    &__element {
        margin-bottom: map.get(configs.$spacing, s-32);

        &--optional {
            // visually hide honeypot field
            left: -999999999px;
            position: absolute;
        }
    }

    &__element-title {
        color: configs.$formColorDescription;
        font: configs.$formFontMobileElementTitle;
        font-weight: 600;
        margin-block-end: map.get(configs.$spacing, s-8);

        @include mixins.breakpoint-from(desktop-small) {
            font: configs.$formFontDesktopElementTitle;
            font-weight: 600;
        }

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
            outline-offset: 4px;
        }
    }

    &__option {
        margin-block: map.get(configs.$spacing, s-8);

        &--inline {
            display: inline-block;
            margin-inline-end: map.get(configs.$spacing, s-32);
        }
    }

    &__checkbox {
        margin-block-start: map.get(configs.$spacing, s-8);
    }

    &__option-value {
        appearance: none;
        background-color: configs.$formColorActionBackground;
        background-image: var(--icon-radio);
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid configs.$formColorActionBorderDefault;
        border-radius: utilities.px-to-rem(24);
        box-sizing: border-box;
        height: utilities.px-to-rem(24);
        left: utilities.px-to-rem(-2);
        margin: utilities.px-to-rem(2);
        padding: utilities.px-to-rem(2);
        position: relative;
        transition: mask 150ms ease-in-out;
        vertical-align: middle;
        width: utilities.px-to-rem(24);

        &:hover,
        &:focus-visible {
            border: 1px solid configs.$formColorActionHoverFocus;
        }

        &:focus-visible {
            outline: utilities.px-to-rem(2) solid configs.$colorBorderOutline;
        }

        &:checked {
            background-color: configs.$formColorActionIcon;
        }
    }

    &__checkbox-value {
        appearance: none;
        background-color: configs.$formColorActionBackground;
        background-image: var(--icon-checkbox);
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid configs.$formColorActionBorderDefault;
        border-radius: 4px;
        box-sizing: border-box;
        height: utilities.px-to-rem(24);
        left: utilities.px-to-rem(-2);
        margin: utilities.px-to-rem(2);
        position: relative;
        transition: mask 150ms ease-in-out;
        vertical-align: middle;
        width: utilities.px-to-rem(24);

        &:hover,
        &:focus-visible {
            border: 1px solid configs.$formColorActionHoverFocus;
        }

        &:focus-visible {
            outline: utilities.px-to-rem(2) solid configs.$colorBorderOutline;
        }

        &:checked {
            background-color: configs.$formColorActionIcon;
        }
    }

    &__option-label,
    &__checkbox-label {
        margin-inline-start: map.get(configs.$spacing, s-8);
        vertical-align: middle;
    }

    &__input-field {
        background-color: configs.$formColorInputFieldOnLightBackground;
        border: 1px solid configs.$formColorInputFieldOnLightBorder;
        border-radius: 4px;
        color: configs.$formColorInputFieldInput;
        font: configs.$formFontMobileText;
        font-size: utilities.px-to-rem(14);
        margin-block-start: map.get(configs.$spacing, s-8);
        padding: map.get(configs.$spacing, s-12) map.get(configs.$spacing, s-16);
        width: 100%;

        &:active,
        &:hover,
        &:focus,
        &:focus-visible {
            border-color: configs.$formColorInputFieldOnLightHoverFocus;
            outline: none;
        }

        &:invalid:not(:focus,:placeholder-shown) {
            border-color: configs.$formColorInputFieldError;
        }

        ::placeholder {
            font: configs.$formFontPlaceholder;
        }
    }

    &--validated {
        .form__input-field,
        .form__checkbox-value,
        .form__option-value,
        .form__input-text-area {
            &:invalid,
            &:invalid:focus {
                border-color: configs.$formColorInputFieldError;
            }
        }
    }

    &__input-text-area {
        height: 90px;
        margin-block-start: map.get(configs.$spacing, s-8);
    }

    &__error {
        display: none;
        margin-block-end: map.get(configs.$spacing, s-32);
        padding-block: map.get(configs.$spacing, s-16);

        &--active {
            display: block;
        }

        .cmp-title {
            --title-text-color: #{configs.$formColorInputFieldError};
            --title-font-desktop-h2: #{configs.$formFontDesktopErrorTitle};
            --title-font-mobile-h2: #{configs.$formFontMobileErrorTitle};
        }

        .form__element-text {
            color: configs.$formColorInputFieldError;
            font: configs.$formFontMobileErrorDescription;

            @include mixins.breakpoint-from(desktop-small) {
                font: configs.$formFontDesktopErrorDescription;
            }
        }
    }

    .button:not(.cmp-button--tertiary) .cmp-button {
        --button-spacing-top: 0;
    }

    .cmp-button--primary {
        display: block;
        text-align: right;

        @include mixins.breakpoint-from(tablet) {
            display: inline-block;
            float: right;
            text-align: initial;
        }
    }

    .cmp-button--secondary {
        display: block;
        margin-block-end: map.get(configs.$spacing, s-16);
        text-align: right;

        @include mixins.breakpoint-from(tablet) {
            display: inline-block;
            float: left;
            margin-block-end: 0;
            text-align: initial;
        }
    }
}
