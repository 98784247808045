@use 'mixins';
@use 'configs';
@use 'sass:map';

.cmp-card-grid,
.cmp-dynamic-list {
    display: grid;
    gap: map.get(configs.$spacing, s-24);
    grid-auto-columns: 1fr;
    max-width: 100%;
    width: 100%;

    @include mixins.breakpoint-from(tablet) {
        gap: map.get(configs.$spacing, s-32);
        grid-auto-columns: minmax(calc((100% - #{map.get(configs.$spacing, s-32)}) / 2), 1fr) minmax(0, calc((100% - #{map.get(configs.$spacing, s-32)}) / 2));
    }

    @include mixins.breakpoint-from(desktop-small) {
        grid-auto-columns: minmax(calc((100% - (2 * #{map.get(configs.$spacing, s-32)})) / 3), 1fr) minmax(0, calc((100% - #{map.get(configs.$spacing, s-32)}) / 2)) minmax(0, calc((100% - (2 * #{map.get(configs.$spacing, s-32)})) / 3));
    }

    .card {
        @include mixins.breakpoint-from(tablet) {
            &:nth-child(odd) {
                grid-column: 1;
            }

            &:nth-child(even) {
                grid-column: 2;
            }
        }

        @include mixins.breakpoint-from(desktop-small) {
            &:nth-child(3n-2) {
                grid-column: 1;
            }

            &:nth-child(3n-1) {
                grid-column: 2;
            }

            &:nth-child(3n) {
                grid-column: 3;
            }
        }
    }
}

.card-grid:not(:first-child),
.dynamic-list:not(:first-child) {
    margin-block-start: map.get(configs.$spacing, s-24);

    @include mixins.breakpoint-from(tablet) {
        margin-block-start: map.get(configs.$spacing, s-32);
    }
}
