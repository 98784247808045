@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.cmp-picture-carousel {
    &::before,
    &::after {
        @include mixins.breakpoint-from(desktop-large) {
            content: '';
            height: 100%;
            position: absolute;
            width: utilities.px-to-rem(55);
            z-index: 1;
        }
    }

    &::before {
        @include mixins.breakpoint-from(desktop-large) {
            background: linear-gradient(90deg, var(--container-background-color) 0%, rgba(255, 255, 255, 0%) 100%);
            inset-block-start: 0;
            inset-inline-start: 0;
        }
    }

    &::after {
        @include mixins.breakpoint-from(desktop-large) {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0%) 0%, var(--container-background-color) 100%);
            inset-block-end: 0;
            inset-inline-end: 0;
        }
    }

    .card--carousel {
        --card-border-radius: 0;
        --card-visual-aspect-ratio: #{map.get(configs.$aspect-ratios, '16-9')};

        @include mixins.breakpoint-from(desktop-small) {
            --card-visual-aspect-ratio: #{map.get(configs.$aspect-ratios, '16-9')};
        }

        .cmp-image__image {
            @include mixins.breakpoint-from(desktop-small) {
                --card-visual-aspect-ratio: #{map.get(configs.$aspect-ratios, '16-9')};
            }
        }
    }
}
