@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.cmp-info-panel {
    background-color: configs.$infoPanelColorBackground;
    display: flex;
    flex-direction: column-reverse;
    padding: map.get(configs.$spacing, s-24);

    @include mixins.breakpoint-from(tablet) {
        flex-direction: row;
        padding: map.get(configs.$spacing, s-32);
    }

    @include mixins.breakpoint-from(desktop-large) {
        padding: map.get(configs.$spacing, s-40);
    }

    &__content {
        flex: 1 0 0;
        min-width: 0;

        .cmp-title {
            --title-text-color: #{configs.$infoPanelColorText};
            --title-font-desktop-h2: #{configs.$infoPanelFontDesktopTitle};
            --title-font-mobile-h2: #{configs.$infoPanelFontMobileTitle};
        }

        .cmp-text {
            --text-color: #{configs.$infoPanelColorText};
            --text-font-mobile: #{configs.$infoPanelFontMobileDescription};
            --text-font-desktop: #{configs.$infoPanelFontDesktopDescription};

            padding-block-start: map.get(configs.$spacing, s-12);
        }
    }

    &__icon {
        line-height: 0;
        margin-block-end: map.get(configs.$spacing, s-16);

        @include mixins.breakpoint-from(tablet) {
            align-self: flex-end;
            margin-block-end: 0;
            margin-inline-start: map.get(configs.$spacing, s-16);
        }

        &::before {
            background-color: configs.$infoPanelColorIcon;
            content: '';
            display: inline-block;
            height: utilities.px-to-rem(48);
            mask-image: var(--icon-lightbulb);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            width: utilities.px-to-rem(48);
        }
    }
}
