@use 'breakpoint-mixins';
@use 'configs';
@use 'sass:map';

.cmp-tabs {
    &__tablist {
        display: inline-block;
        max-width: 100%;
        text-decoration: none;

        @include breakpoint-mixins.breakpoint-from(tablet) {
            display: flex;
        }
    }

    &__tab {
        border-bottom: 2px solid configs.$tabColorBorderDefault;
        color: configs.$tabColorText;
        display: block;
        font: configs.$tabFontText;
        max-width: 100%;
        overflow: hidden;
        padding: map.get(configs.$spacing, s-24);
        text-align: left;
        text-overflow: ellipsis;
        transition: border-bottom-color 150ms ease-in-out;
        white-space: nowrap;
        width: auto;

        @include breakpoint-mixins.breakpoint-from(tablet) {
            flex: 1 0 auto;
            min-width: 25%;
            text-align: center;

            &:nth-child(n+4) {
                flex-grow: 0.5;

                &:last-child {
                    justify-self: flex-start;
                }
            }
        }

        &:hover,
        &:focus-visible {
            color: configs.$tabColorBorderActive;
            transition: color 150ms ease-in-out;
        }

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
        }

        &--active {
            border-bottom: 4px solid configs.$tabColorBorderActive;
        }
    }
}

.tabs:not(:first-child) {
    margin-block-start: map.get(configs.$spacing, s-24);

    @include breakpoint-mixins.breakpoint-from(tablet) {
        margin-block-start: map.get(configs.$spacing, s-32);
    }
}
