@use 'sass:list';

/* usage:
.cmp-image__image {
    aspect-ratio: map.get($aspect-ratios, '3-4');
} */


$aspect-ratios: (
        '1-1': list.slash(1, 1),
        '4-3': list.slash(4, 3),
        '3-4': list.slash(3, 4),
        '3-2': list.slash(3, 2),
        '2-3': list.slash(2, 3),
        '16-9': list.slash(16, 9),
        '9-16': list.slash(9, 16),
        '21-9': list.slash(21, 9),
);
