
// Do not edit directly
// Generated on Fri, 17 Jan 2025 09:37:15 GMT

$highlightFontDesktopDefault: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$highlightFontDesktopBold: 700 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$highlightFontMobileDefault: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$highlightFontMobileBold: 700 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$richTextFontDesktopDefault: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$richTextFontDesktopBold: 700 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$richTextFontMobileDefault: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$richTextFontMobileBold: 700 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$richTextColorTextOnLightLink: #005ca9;
$richTextColorTextOnLightDefault: #5f666d;
$richTextColorTextOnLightSubText: #5f666dB3;
$richTextColorTextOnDarkLink: #cad7e3;
$richTextColorTextOnDarkDefault: #ffffff;
$richTextColorTextOnDarkSubText: rgba(255,255,255,0.7);
$richTextColorTableTitle: #00244a;
$richTextColorTableText: #5f666d;
$richTextColorTableHeaderBackground: #edf5fa;
$richTextColorTableItemBackground: #ffffff;
$richTextColorTableBorder: #f0f1f2;
