
// Do not edit directly
// Generated on Fri, 17 Jan 2025 09:37:15 GMT

$cookieColorBackground: #00244a;
$cookieColorText: #ffffff;
$cookieColorBullets: rgba(255,255,255,0.7);
$cookieColorHoverFocus: #cad7e3;
$cookieColorPopupBackground: #00244a;
$cookieColorPopupText: #ffffff;
$cookieColorBorder: #f0f1f2;
$cookieFontDesktopTitle: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$cookieFontDesktopPopup: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$cookieFontDesktopText: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$cookieFontDesktopSubtitle: 700 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$cookieFontMobileTitle: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$cookieFontMobilePopup: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$cookieFontMobileText: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$cookieFontMobileSubtitle: 700 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$cookiePreferencesColorTitle: #00244a;
$cookiePreferencesColorRunningText: #5f666d;
$cookiePreferencesColorBackground: #f9f9f9;
$cookiePreferencesColorIconDefault: #00244a;
$cookiePreferencesColorIconHoverFocus: #005ca9;
$cookiePreferencesFontDesktopTitle: 100 44px/1.5 Roboto, Arial, Helvetica, sans-serif;
$cookiePreferencesFontDesktopSubtitle: 100 36px/1.5 Roboto, Arial, Helvetica, sans-serif;
$cookiePreferencesFontDesktopText: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$cookiePreferencesFontMobileTitle: 100 36px/1.5 Roboto, Arial, Helvetica, sans-serif;
$cookiePreferencesFontMobileSubtitle: 100 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$cookiePreferencesFontMobileText: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
