@use 'configs';
@use 'mixins';
@use 'sass:map';
@use 'utilities';

.breadcrumb {
    .cmp-breadcrumb {
        margin-block: map.get(configs.$spacing, s-16);

        @include mixins.breakpoint-from(tablet) {
            margin-block: map.get(configs.$spacing, s-32);
        }

        &__item,
        &__item-link {
            color: configs.$breadcrumbColorDefault;
            font: configs.$breadcrumbFontMobileDefault;
            text-decoration: none;

            @include mixins.breakpoint-from(tablet) {
                font: configs.$breadcrumbFontDesktopDefault;
            }
        }

        &__item {
            >span[itemprop='name'] {
                display: inline-block;
                margin-inline-end: map.get(configs.$spacing, s-16);
                padding-block: map.get(configs.$spacing, s-8);
            }

            [itemprop='name'] {
                position: relative;
                vertical-align: text-top;
            }
        }

        &__item-link {
            display: inline-block;
            margin-inline-end: map.get(configs.$spacing, s-16);
            padding-block: map.get(configs.$spacing, s-8);

            &:focus-visible {
                outline: 2px solid configs.$colorBorderOutline;
                outline-offset: 4px;
            }

            &:hover [itemprop='name'],
            &:focus-visible [itemprop='name'] {
                color: configs.$breadcrumbColorHover;
                text-decoration: underline;
                transition: color 150ms ease-in-out;
            }

            &::after {
                background-color: configs.$breadcrumbColorDefault;
                content: '';
                display: inline-block;
                height: utilities.px-to-rem(18);
                margin-inline-start: map.get(configs.$spacing, s-16);
                mask-image: var(--icon-chevron-right);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: relative;
                vertical-align: middle;
                width: utilities.px-to-rem(18);
            }
        }
    }
}
