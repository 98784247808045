@use 'configs';
@use 'mixins';

.cmp-dynamic-list-teaser {
    border-radius: var(--card-border-radius);
    text-decoration: none;

    &:focus-visible {
        outline: 2px solid configs.$colorBorderOutline;
    }

    .button:not(:last-child) {
        margin-block-end: var(--card-spacing-bottom-mobile);

        @include mixins.breakpoint-from(tablet) {
            margin-block-end: var(--card-spacing-bottom-tablet);
        }

        @include mixins.breakpoint-from(desktop-small) {
            margin-block-end: var(--card-spacing-bottom-desktop);
        }
    }

    .cmp-link__screen-reader-only {
        @include mixins.visually-hidden;
    }
}
