@use 'configs';
@use 'sass:map';

.cmp-page {
    &__skiptomaincontent {
        background-color: configs.$skipLinkColorBackground;

        .cmp-page__skiptomaincontent-link {
            &:focus,
            &:active {
                background-color: configs.$skipLinkColorBackground;
                border: 0;
                color: configs.$skipLinkColorLink;
                display: inline-block;
                font: configs.$skipLinkFontDefault;
                height: auto;
                left: unset;
                margin: map.get(configs.$spacing, s-24) map.get(configs.$spacing, s-64);
                outline: 2px solid configs.$colorBorderOutline;
                outline-offset: 2px;
                padding: 0;
                position: relative;
                top: unset;
                width: auto;
            }
        }
    }
}
