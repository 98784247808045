@use 'configs';
@use 'mixins';
@use 'sass:map';
@use 'utilities';

.cmp-accordion {
    &__title {
        font: configs.$accordionFontContentMobileTitle;

        @include mixins.breakpoint-from(tablet) {
            font: configs.$accordionFontContentDesktopTitle;
        }
    }

    &__icon {
        background-color: configs.$accordionColorButtonBackground;
        border-radius: utilities.px-to-rem(100);
        height: utilities.px-to-rem(34);
        margin-inline-start: map.get(configs.$spacing, s-40);
        width: utilities.px-to-rem(34);

        @include mixins.breakpoint-from(tablet) {
            height: utilities.px-to-rem(40);
            width: utilities.px-to-rem(40);
        }

        &::after {
            background-color: configs.$accordionColorButtonIcon;
            content: '';
            display: inline-block;
            height: utilities.px-to-rem(18);
            margin: map.get(configs.$spacing, s-8);
            mask-image: var(--icon-plus);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            transition: mask 150ms ease-in-out;
            width: utilities.px-to-rem(18);

            @include mixins.breakpoint-from(tablet) {
                height: utilities.px-to-rem(24);
                width: utilities.px-to-rem(24);
            }
        }
    }

    &__button {
        align-items: center;
        color: configs.$accordionColorTitle;
        display: flex;
        justify-content: space-between;

        &:hover,
        &:focus-visible {
            .cmp-accordion__title {
                color: configs.$accordionColorHoverFocus;
                transition: color 150ms ease-in-out;
            }

            .cmp-accordion__icon {
                background-color: configs.$accordionColorHoverFocus;
                transition: background-color 150ms ease-in-out;
            }
        }

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
            outline-offset: 4px;
        }

        &--expanded {
            .cmp-accordion__icon {
                &::after {
                    mask-image: var(--icon-minus);
                }
            }
        }
    }

    &__item {
        border-block-end: utilities.px-to-rem(1) solid configs.$accordionColorSeparator;
        padding-block: map.get(configs.$spacing, s-24);
    }

    &__panel {
        .cmp-container {
            .cmp-text {
                --text-color: #{configs.$accordionColorText};
                --text-font-mobile: #{configs.$accordionFontContentMobileText};
                --text-font-desktop: #{configs.$accordionFontContentDesktopText};
            }

            .cmp-text,
            .cmp-image,
            .cmp-button--primary a,
            .cmp-embed {
                margin-block-start: map.get(configs.$spacing, s-24);

                @include mixins.breakpoint-from(tablet) {
                    margin-block-start: map.get(configs.$spacing, s-32);
                }
            }
        }
    }
}
