@use 'configs';
@use 'mixins';
@use 'sass:map';
@use 'utilities';

.embed {
    aspect-ratio: 16 / 9;
    overflow: hidden;

    &__fallback {
        align-items: center;
        aspect-ratio: 16 / 9;
        background-color: configs.$embedColorBackground;
        color: configs.$embedColorText;
        display: flex;
        flex-direction: column;
        font: configs.$embedFontMobileText;
        gap: 1em;
        justify-content: center;
        padding: 1em;
        position: relative;
        text-decoration: none;
        width: 100%;

        @include mixins.breakpoint-from(desktop-small) {
            font: configs.$embedFontDesktopText;
        }

        /* right-facing triangle "Play" icon */
        &::before {
            border: solid transparent;
            border-left-color: configs.$embedColorArrow;
            border-width: 0.6em 0 0.6em 1.2em;
            content: '';
            display: block;
            margin-left: 0.4rem;
            margin-top: -0.6rem;
            position: absolute;
            top: 50%;
            z-index: var(--zindex-embed-play);
        }

        &::after {
            border-radius: 0.5rem/0.8rem;
            content: '';
            display: block;
            height: 2.8rem;
            margin-top: -1.4rem;
            position: absolute;
            top: 50%;
            width: 4rem;
        }

        &--youtube {
            &::after {
                background-color: configs.$embedColorVideoYoutube;
            }
        }

        &--vimeo {
            &::after {
                background-color: configs.$embedColorVideoVimeo;
            }
        }

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
        }

        .cmp-link__screen-reader-only {
            @include mixins.visually-hidden;
        }
    }

    &__label {
        margin-block-start: 4.2rem;
        overflow: hidden;
        padding-block-start: 4rem;
        text-align: center;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        width: 100%;
    }

    &__target {
        aspect-ratio: 16 / 9;
        display: block;
        position: relative;
        width: 100%;
    }

}
