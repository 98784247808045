@use 'configs';
@use 'mixins';
@use 'sass:map';

.dealer-quote {
    display: block;
    float: left;
    margin-block: map.get(configs.$spacing, s-48);
    width: 100%;

    @include mixins.breakpoint-from(tablet) {
        margin-block: map.get(configs.$spacing, s-64);
    }

    @include mixins.breakpoint-from(desktop-small) {
        margin-block: map.get(configs.$spacing, s-80);
    }

    &__stepper {
        display: block;
        width: 100%;
    }

    &__wizard {
        margin-block-end: map.get(configs.$spacing, s-48);
    }

    .info-panel {
        margin-block-start: map.get(configs.$spacing, s-24);

        @include mixins.breakpoint-from(tablet) {
            margin-block-start: map.get(configs.$spacing, s-32);
        }

        @include mixins.breakpoint-from(desktop-small) {
            margin-block-start: map.get(configs.$spacing, s-48);
        }
    }
}
