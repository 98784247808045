@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.cmp-highlight {
    // Base styling
    margin-block: map.get(configs.$spacing, s-24);

    @include mixins.breakpoint-from(tablet) {
        margin-block: map.get(configs.$spacing, s-32);
    }

    &__container {
        min-height: utilities.px-to-rem(220);

        @include mixins.breakpoint-from(desktop-small) {
            display: grid;
            grid-template-columns: 1fr repeat(12, minmax(0, calc(#{var(--content-max-width)} / 12))) 1fr;
            min-height: utilities.px-to-rem(290);
        }
    }

    &__image {
        background-color: configs.$imageColorBackup;

        @include mixins.breakpoint-from(desktop-small) {
            grid-column: 1 / span 7;
            grid-row: 1 / -1;
            position: relative;
        }

        .image {
            @include mixins.breakpoint-from(desktop-small) {
                height: 100%;
                position: absolute;
                width: 100%;
            }

            .cmp-image {
                &__image {
                    aspect-ratio: map.get(configs.$aspect-ratios, '16-9');
                    display: block;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;

                    @include mixins.breakpoint-from(desktop-small) {
                        aspect-ratio: auto;
                        position: absolute;
                    }
                }
            }
        }
    }

    &__content {
        background-color: var(--highlight-bg-color);
        box-shadow: configs.$topBlue20a;
        color: configs.$highlightColorTextDefault;
        padding: map.get(configs.$spacing, s-24);

        @include mixins.breakpoint-from(tablet) {
            padding: map.get(configs.$spacing, s-40);
        }

        @include mixins.breakpoint-from(desktop-small) {
            display: grid;
            grid-column: 8 / -1;
            grid-row: 1 / -1;
            grid-template-columns: subgrid;
        }

        .cmp-title {
            --title-text-color: #{configs.$highlightColorTextDefault};
            --title-font-desktop-h2: #{configs.$highlightFontDesktopTitle};
            --title-font-mobile-h2: #{configs.$highlightFontMobileTitle};

            margin-block-end: map.get(configs.$spacing, s-12);
        }

        .cmp-text {
            --text-color: #{configs.$highlightColorTextDefault};
            --text-list-marker: #{configs.$highlightColorTextBullets};
            --text-font-mobile: #{configs.$highlightFontMobileDescription};
            --text-font-desktop: #{configs.$highlightFontDesktopDescription};

            p,
            ol,
            ul {
                margin-block-end: map.get(configs.$spacing, s-8);
            }

            ol,
            ul, {
                li {
                    padding-block-end: map.get(configs.$spacing, s-4);

                    &:last-child {
                        padding-block-end: 0;
                    }
                }
            }

            ul {
                list-style-position: outside;

                li {
                    padding-inline-start: map.get(configs.$spacing, s-12);
                }
            }

            ol {
                padding-inline-start: unset;
            }
        }
    }

    &__content-wrapper {
        @include mixins.breakpoint-from(desktop-small) {
            grid-column: 1 / span 6;
        }
    }

    // Image renditions styling
    .rendition--image-left & {
        --highlight-bg-color: #{configs.$highlightColorBackgroundDark};

        &__content {
            @include mixins.breakpoint-from(desktop-small) {
                box-shadow: configs.$sideLeftBlue40a;
            }
        }
    }

    .rendition--image-right & {
        --highlight-bg-color: #{configs.$highlightColorBackgroundAccent};

        .cmp-highlight__image {
            @include mixins.breakpoint-from(desktop-small) {
                grid-column: 8 / -1;
            }
        }

        &__content {
            @include mixins.breakpoint-from(desktop-small) {
                box-shadow: configs.$sideRightBlue40a;
                grid-column: 1 / span 7;
            }
        }

        &__content-wrapper {
            @include mixins.breakpoint-from(desktop-small) {
                grid-column: 2 / span 6;
            }

        }
    }

    .rendition--image-overlay & {
        --highlight-bg-color: #{configs.$highlightColorBackgroundAccent};

        &__image {
            @include mixins.breakpoint-from(desktop-small) {
                aspect-ratio: map.get(configs.$aspect-ratios, '16-9');
                grid-column: 2 / -1;
                grid-row: 1 / -1;
            }
        }

        &__content {
            @include mixins.breakpoint-from(desktop-small) {
                align-items: end;
                box-shadow: configs.$sideRightBlue40a;
                grid-column: 1 / span 7;
                grid-row: 1 / -1;
                grid-template-rows: max-content;
                margin-block: auto map.get(configs.$spacing, s-64);
                z-index: 1;
            }

            .cmp-title__text {
                @include mixins.breakpoint-from(desktop-small) {
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                }
            }
        }

        &__content-wrapper {
            @include mixins.breakpoint-from(desktop-small) {
                grid-column: 2 / span 7;
            }
        }
    }
}

.themable-layout-container {
    + .highlight .cmp-highlight {
        margin-block-start: 0;
    }
}
