@media (prefers-reduced-motion: reduce) {
    *,
    *::after,
    *::before {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        scroll-behavior: auto !important;
        transition-duration: 0.01ms !important;
    }
}

// Add animations and keyframes here
@keyframes carouselItemFade {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.5;
    }
}
