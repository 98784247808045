@use 'design-tokens/decision';

:root {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    font: decision.$fontPrimaryRegular16;
}

h2 {
    font: decision.$fontSecondaryMedium16;
}
