@use 'breakpoint-mixins';
@use 'configs';
@use 'sass:map';

.cmp-tab-item {
    display: block;
    overflow: auto;
    padding-block-start: map.get(configs.$spacing, s-24);

    .text {
        @include breakpoint-mixins.breakpoint-from(desktop-small) {
            display: inline-block;
            margin-inline-end: map.get(configs.$spacing, s-32);
            width: calc((100% - #{map.get(configs.$spacing, s-32)}) / 2);
        }
    }

    .image {
        @include breakpoint-mixins.breakpoint-from(desktop-small) {
            display: inline-block;
            float: right;
            overflow: auto;
            width: calc((100% - #{map.get(configs.$spacing, s-32)}) / 2);
        }
    }

    .cmp-image {
        margin-block-start: map.get(configs.$spacing, s-32);

        @include breakpoint-mixins.breakpoint-from(desktop-small) {
            margin-block-start: 0;
        }

        &__image {
            aspect-ratio: map.get(configs.$aspect-ratios, '16-9');
            object-fit: cover;
            overflow: hidden;
        }
    }
}
