@use 'configs';
@use 'mixins';

.cmp-download {
    &__title-link {
        color: configs.$downloadLinkColorDefault;
        font: configs.$downloadLinkFontMobileDefault;

        @include mixins.breakpoint-from(tablet) {
            font: configs.$downloadLinkFontDesktopDefault;
        }

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
            outline-offset: 2px;
        }
    }

    &__description,
    &__action {
        display: none;
    }
}
