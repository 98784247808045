@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.cmp-contact-grid {
    @include mixins.breakpoint-from(desktop-small) {
        display: grid;
        gap: map.get(configs.$spacing, s-40);
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
    }

    .cmp-contact-card {
        @include mixins.breakpoint-from(desktop-small) {
            display: contents;
        }
    }

    .contact-card {
        background-color: configs.$contactColorBackground;
        border: 1px solid configs.$contactColorBorder;
        box-shadow: configs.$centerBlue15a;
        box-sizing: border-box;
        display: inline-block;
        margin-block-end: map.get(configs.$spacing, s-24);
        max-width: 100%;
        padding: map.get(configs.$spacing, s-24);
        width: 100%;

        &:last-child {
            margin-block-end: 0;
        }

        @include mixins.breakpoint-from(tablet) {
            display: grid;
            margin-block-end: map.get(configs.$spacing, s-32);
            padding: map.get(configs.$spacing, s-32);
        }

        @include mixins.breakpoint-from(desktop-small) {
            display: grid;
            margin-block-end: 0;
            padding: map.get(configs.$spacing, s-40);
        }

        &__headshot {
            aspect-ratio: map.get(configs.$aspect-ratios, '1-1');
            background: configs.$imageColorBackup;
            border-radius: 50%;
            margin-inline: auto;
            overflow: hidden;
            width: utilities.px-to-rem(105);

            @include mixins.breakpoint-from(tablet) {
                display: inline-block;
                flex-grow: 0;
                flex-shrink: 0;
                margin-inline: 0 map.get(configs.$spacing, s-40);
                vertical-align: middle;
            }

            .cmp-image {
                height: 100%;
                width: 100%;

                &__image {
                    background-color: configs.$imageColorBackground;
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }

        &__main-info {
            margin-block: map.get(configs.$spacing, s-24);

            @include mixins.breakpoint-from(tablet) {
                display: inline-block;
                flex-grow: 0;
                flex-shrink: 1;
                margin-block: auto;
                vertical-align: middle;
            }
        }

        &__profile {
            @include mixins.breakpoint-from(tablet) {
                align-items: center;
                display: flex;
                margin-block-end: map.get(configs.$spacing, s-32);
            }

            @include mixins.breakpoint-from(desktop-small) {
                align-self: self-start;
            }
        }

        &__region {
            color: configs.$contactColorTextRegion;
            font: configs.$contactFontMobileMeta;

            @include mixins.breakpoint-from(tablet) {
                font: configs.$contactFontDesktopMeta;
            }
        }

        &__name {
            color: configs.$contactColorTextName;
            font: configs.$contactFontMobileName;

            @include mixins.breakpoint-from(tablet) {
                font: configs.$contactFontDesktopName;
            }
        }

        &__detailed-info {
            @include mixins.breakpoint-from(desktop-small) {
                align-self: self-end;
            }
        }

        &__email-link,
        &__phone-link {
            text-decoration: none;
        }

        &__email-link + .contact-card__phone-link {
            display: inline-block;
            margin-block-start: map.get(configs.$spacing, s-8);
        }

        &__email,
        &__phone {
            &::before {
                background-color: configs.$contactColorIcon;
                content: '';
                display: inline-block;
                height: map.get(configs.$spacing, s-24);
                margin-inline-end: map.get(configs.$spacing, s-8);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                vertical-align: text-bottom;
                width: map.get(configs.$spacing, s-24);
            }
        }

        &__email {
            color: configs.$contactColorTextLinks;
            font: configs.$contactFontMobileDescription;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @include mixins.breakpoint-from(tablet) {
                font: configs.$contactFontDesktopDescription;
            }

            &::before {
                mask-image: var(--icon-email);
            }
        }

        &__phone {
            color: configs.$contactColorTextLinks;
            display: inline-block;
            font: configs.$contactFontMobileDescription;

            @include mixins.breakpoint-from(tablet) {
                font: configs.$contactFontDesktopDescription;
            }

            &::before {
                mask-image: var(--icon-phone);
            }
        }

        &__hours {
            color: configs.$contactColorTextDescription;
            display: inline-block;
            font: configs.$contactFontMobileDescription;
            margin-inline-start: map.get(configs.$spacing, s-8);

            @include mixins.breakpoint-from(tablet) {
                font: configs.$contactFontDesktopDescription;
            }
        }
    }
}

.contact-grid:not(:first-child) {
    margin-block-start: map.get(configs.$spacing, s-24);

    @include mixins.breakpoint-from(tablet) {
        margin-block-start: map.get(configs.$spacing, s-32);
    }
}
