@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.cmp-cookie-preferences {
    background-color: configs.$cookiePreferencesColorBackground;
    padding-block: map.get(configs.$spacing, s-48);

    @include mixins.breakpoint-from(tablet) {
        padding-block: map.get(configs.$spacing, s-64);
    }

    @include mixins.breakpoint-from(desktop-small) {
        padding-block: map.get(configs.$spacing, s-80);
    }

    &__content {
        max-width: var(--text-readable-width);

        @include mixins.breakpoint-from(desktop-small) {
            margin-inline: calc(50% - 50vw);
            transform: translateX(calc(50vw - 50%));
        }
    }

    .cmp-title {
        --title-text-color: #{configs.$cookiePreferencesColorTitle};
        --title-font-desktop-h2: #{configs.$cookiePreferencesFontDesktopTitle};
        --title-font-mobile-h2: #{configs.$cookiePreferencesFontMobileTitle};
        --title-font-desktop-h3: #{configs.$cookiePreferencesFontDesktopSubtitle};
        --title-font-mobile-h3: #{configs.$cookiePreferencesFontMobileSubtitle};

        margin-block-end: #{map.get(configs.$spacing, s-8)};

        @include mixins.breakpoint-from(tablet) {
            margin-block-end: #{map.get(configs.$spacing, s-12)};
        }
    }

    .cmp-text {
        --text-color: #{configs.$cookiePreferencesColorRunningText};
        --text-font-mobile: #{configs.$cookiePreferencesFontMobileText};
        --text-font-desktop: #{configs.$cookiePreferencesFontDesktopText};
    }

    &__additional {
        margin-block: #{map.get(configs.$spacing, s-24)};
    }

    &__item {
        column-gap: #{map.get(configs.$spacing, s-16)};
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        align-content: flex-start;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: map.get(configs.$spacing, s-16);

        @include mixins.breakpoint-from(tablet) {
            column-gap: map.get(configs.$spacing, s-24);
        }
    }

    .cmp-button {
        --button-spacing-top: 0;
    }

    &__notification {
        background-color: configs.$cookieColorPopupBackground;
        bottom: 0;
        color: configs.$cookieColorPopupText;
        display: block;
        font: configs.$cookieFontMobilePopup;
        padding: map.get(configs.$spacing, s-24);
        position: fixed;
        right: 0;
        width: 100%;

        @include mixins.breakpoint-from(tablet) {
            font: configs.$cookieFontDesktopPopup;
            margin: auto map.get(configs.$spacing, s-32) map.get(configs.$spacing, s-32) auto;
            max-width: utilities.px-to-rem(482);
            padding: map.get(configs.$spacing, s-40);
        }
    }

    .cookie {
        &__title,
        &__text {
            color: configs.$formColorLabel;
            font: configs.$formFontMobileText;

            @include mixins.breakpoint-from(tablet) {
                font: configs.$formFontDesktopText;
            }
        }

        &__text {
            margin-inline-start: map.get(configs.$spacing, s-32);
        }

        &__tooltip {
            display: inline-block;
            line-height: 0;

            &::after {
                background-color: currentcolor;
                content: '';
                display: inline-block;
                height: utilities.px-to-rem(24);
                mask-image: var(--icon-info);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                position: relative;
                vertical-align: middle;
                width: utilities.px-to-rem(24);
            }

            &:focus-visible {
                border-radius: 50%;
                outline: 2px solid configs.$colorBorderOutline;
                outline-offset: 2px;
            }
        }
    }
}
