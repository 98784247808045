@use 'breakpoint-mixins';
@use 'configs';
@use 'sass:map';
@use 'mixins';
@use 'utilities';

.cmp-inspiration-block {
    &__content {
        .cmp-title {
            margin-block-end: map.get(configs.$spacing, s-8);

            &__text {
                --title-text-color: #{configs.$titleColorDefault};

                margin-block-end: map.get(configs.$spacing, s-12);
            }
        }

        .cmp-text {
            --text-color: #{configs.$richTextColorTextOnLightDefault};
            --text-font-desktop: #{configs.$richTextFontDesktopDefault};
            --text-font-mobile: #{configs.$richTextFontMobileDefault};

            margin-block-end: map.get(configs.$spacing, s-32);
        }
    }
}

.cmp-inspiration-grid {
    max-width: 100%;
    width: 100%;

    @include breakpoint-mixins.breakpoint-from(desktop-small) {
        display: grid;
        gap: map.get(configs.$spacing, s-32);
        grid-auto-columns: minmax(calc((100% - #{map.get(configs.$spacing, s-32)}) / 2), 1fr) minmax(0, calc((100% - #{map.get(configs.$spacing, s-32)}) / 2));
    }

    &__card {
        &:not(:last-child) {
            margin-block-end: map.get(configs.$spacing, s-24);

            @include breakpoint-mixins.breakpoint-from(tablet) {
                margin-block-end: map.get(configs.$spacing, s-32);
            }

            @include breakpoint-mixins.breakpoint-from(desktop-small) {
                margin-block-end: 0;
            }
        }

        @include breakpoint-mixins.breakpoint-from(desktop-small) {
            &:nth-child(odd) {
                grid-column: 1;
            }

            &:nth-child(even) {
                grid-column: 2;
            }
        }
    }
}

.inspiration-block:not(:first-child) {
    margin-block: map.get(configs.$spacing, s-24) map.get(configs.$spacing, s-48);

    @include breakpoint-mixins.breakpoint-from(tablet) {
        margin-block: map.get(configs.$spacing, s-32) map.get(configs.$spacing, s-64);
    }

    @include breakpoint-mixins.breakpoint-from(desktop-small) {
        margin-block: map.get(configs.$spacing, s-32) map.get(configs.$spacing, s-80);
    }
}
