@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.cmp-content-carousel {
    @include mixins.breakpoint-from(desktop-large) {
        max-width: calc(#{var(--content-max-width)} * 2);
        position: relative;
    }

    &::before,
    &::after {
        @include mixins.breakpoint-from(desktop-large) {
            content: '';
            height: 100%;
            position: absolute;
            width: utilities.px-to-rem(55);
            z-index: 1;
        }
    }

    &::before {
        @include mixins.breakpoint-from(desktop-large) {
            background: linear-gradient(90deg, var(--container-background-color) 0%, rgba(255, 255, 255, 0%) 100%);
            inset-block-start: 0;
            inset-inline-start: 0;
        }
    }

    &::after {
        @include mixins.breakpoint-from(desktop-large) {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0%) 0%, var(--container-background-color) 100%);
            inset-block-end: 0;
            inset-inline-end: 0;
        }
    }

    .swiper-slide {
        --swiper-slide-space-between: #{utilities.px-to-rem(16)};

        align-self: stretch;
        height: unset;

        @include mixins.breakpoint-from(tablet) {
            width: calc(50% - var(--swiper-slide-space-between));
        }

        @include mixins.breakpoint-from(desktop-small) {
            --swiper-slide-space-between: #{utilities.px-to-rem(32)};
        }
    }
}
