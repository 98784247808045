@use 'configs';

// Accessible for screen readers with element removed from document flow
@mixin visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

@mixin visually-hidden-focusable {
    &:not(:focus, :focus-within) {
        @include visually-hidden;
    }
}
