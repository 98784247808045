@use 'sass:map';
@use 'breakpoint-mixins';
@use 'configs';

@mixin content-container {
    display: grid;
    grid-template-columns: [full-start] var(--grid-offset) [wrapper-start] minmax(0, var(--content-max-width)) [wrapper-end] var(--grid-offset) [full-end];

    > * {
        grid-column: wrapper;
    }

    @include breakpoint-mixins.breakpoint-from(tablet) {
        --grid-offset: #{map.get(configs.$spacing, s-40)};
    }

    // custom breakpoint for grid layout fix
    // 1280px = 1200px max content width + (40px padding * 2)
    @media screen and (width >= 1280px) {
        --grid-offset: 1fr;
    }
}

@mixin full-width {
    margin-inline: calc(50% - 50vw);

    > * {
        @include content-container;
    }
}
