@use 'sass:map';
@use 'configs';

@mixin breakpoint-from($point, $value: 0) {
    @if $point == tablet {
        @media screen and (min-width: map.get(configs.$deceuninck-breakpoints, tablet)) {
            @content;
        }
    } @else if $point == desktop-small {
        @media screen and (min-width: map.get(configs.$deceuninck-breakpoints, desktop-small)) {
            @content;
        }
    } @else if $point == desktop-large {
        @media screen and (min-width: map.get(configs.$deceuninck-breakpoints, desktop-large)) {
            @content;
        }
    } @else {
        @media screen and ($point: $value) {
            @content;
        }
    }

}
