@use 'distance-function';
@use 'sass:map';
@use 'sass:math';

/* usage:
.button {
    padding: map.get($spacing, s-32);
} */

$spacing-sizelist: 4 8 12 16 24 32 40 48 64 72 80 96 120;
$spacing: ();

@each $key in $spacing-sizelist {
    $new: (s-#{$key}: #{math.div($key, 16)}rem);
    $spacing: map.merge($spacing, $new);
}
