@use 'sass:map';

/* stylelint-disable length-zero-no-unit */

/* FROM DESIGNER:
Mobile: 0 - 480px
Tablet: 481px - 1024px
Desktop 1025px - 1440px
Desktop big (elements stop sizing up): 1441px - ... */

$deceuninck-breakpoints: (
        tablet: 481px,
        desktop-small: 1025px,
        desktop-large: 1441px
);
/* stylelint-enable */
