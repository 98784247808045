@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.carousel {
    &__controls {
        column-gap: map.get(configs.$spacing, s-8);
        display: flex;
        justify-content: flex-end;
        padding-block: map.get(configs.$spacing, s-16) map.get(configs.$spacing, s-8);
    }

    &__control {
        background: configs.$controlsColorBackground;
        border: 2px solid configs.$controlsColorBorder;
        border-radius: 100%;
        box-sizing: content-box;
        cursor: pointer;
        height: utilities.px-to-rem(24);
        padding: utilities.px-to-rem(13);
        width: utilities.px-to-rem(24);

        &:hover,
        &:focus,
        &:focus-visible {
            background: configs.$controlsColorHoverFocus;
            border-color: configs.$controlsColorHoverFocus;

            .carousel__control__icon::before {
                background: configs.$controlsColorIconReversed;
            }
        }

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
            outline-offset: 4px;
        }

        &[aria-disabled='true'] {
            cursor: not-allowed;
            opacity: 0.5;

            &:hover,
            &:focus {
                background: configs.$controlsColorBackground;
                border-color: configs.$controlsColorBorder;

                .carousel__control__icon::before {
                    background: configs.$controlsColorIcon;
                }
            }
        }

        &__icon {
            display: block;
            transform: translateX(1px);

            &::before {
                background: configs.$controlsColorIcon;
                content: '';
                display: block;
                height: utilities.px-to-rem(24);
                mask-image: var(--icon-chevron-right);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                width: utilities.px-to-rem(24);
            }
        }

        &--prev {
            .carousel__control__icon {
                transform: rotateY(180deg) translateX(1px);
            }
        }
    }
}
