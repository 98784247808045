@use 'breakpoint-mixins';
@use 'configs';
@use 'sass:map';

.cmp-intro-block {
    .title {
        display: block;
        margin-block-end: map.get(configs.$spacing, s-12);
        width: 100%;

        .cmp-title {
            --title-text-color: #{configs.$introBlockColorTitle};
            --title-font-desktop-h2: #{configs.$introBlockFontDesktopTitle};
            --title-font-mobile-h2: #{configs.$introBlockFontMobileTitle};
        }
    }

    &__split-container {
        width: 100%;

        @include breakpoint-mixins.breakpoint-from(desktop-small) {
            display: grid;
            gap: map.get(configs.$spacing, s-32);
            grid-auto-columns: minmax(50%, 1fr) minmax(0, 1fr);
            max-width: 100%;
        }

        .image {
            @include breakpoint-mixins.breakpoint-from(desktop-small) {
                display: contents;
            }

            .cmp-image {
                @include breakpoint-mixins.breakpoint-from(desktop-small) {
                    grid-column: 2;
                }

                &__image {
                    aspect-ratio: map.get(configs.$aspect-ratios, '16-9');
                    object-fit: cover;
                    overflow: hidden;
                }
            }
        }
    }

    &__description {
        margin-block-end: map.get(configs.$spacing, s-24);

        @include breakpoint-mixins.breakpoint-from(desktop-small) {
            grid-column: 1;
            margin-block-end: 0;
        }

        .text {
            .cmp-text {
                --text-color: #{configs.$introBlockColorDescription};
                --text-list-marker: #{configs.$introBlockColorBullets};
                --text-font-mobile: #{configs.$introBlockFontMobileDescription};
                --text-font-desktop: #{configs.$introBlockFontDesktopDescription};

                padding: 0;

                > ol,
                > ul {
                    margin-block: map.get(configs.$spacing, s-8);
                    padding-inline-start: 0;
                }

                ol,
                ul {
                    &:last-child {
                        margin-block-end: 0;

                        li:last-child {
                            margin-block-end: 0;
                        }
                    }
                }

                ul li,
                ol li {
                    margin-block: map.get(configs.$spacing, s-4);
                }
            }
        }
    }
}

.intro-block:not(:first-child) {
    margin-block-start: map.get(configs.$spacing, s-24);

    @include breakpoint-mixins.breakpoint-from(tablet) {
        margin-block-start: map.get(configs.$spacing, s-32);
    }
}
