
// Do not edit directly
// Generated on Fri, 17 Jan 2025 09:37:15 GMT

$infoPanelFontDesktopTitle: 500 28px/1.5 Roboto, Arial, Helvetica, sans-serif;
$infoPanelFontDesktopDescription: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$infoPanelFontMobileTitle: 500 24px/1.5 Roboto, Arial, Helvetica, sans-serif;
$infoPanelFontMobileDescription: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$infoPanelColorText: #00244a;
$infoPanelColorIcon: #00244a;
$infoPanelColorBackground: #cad7e3;
