@use 'configs';
@use 'utilities';
@use 'mixins';
@use 'sass:map';

.cmp-navigation {
    &__content {
        background-color: configs.$dropdownColorBackgroundPrimary;
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: map.get(configs.$deceuninck-breakpoints, tablet);
    }

    .segments-bar {
        &__menu {
            display: flex;
        }

        &__link {
            color: configs.$mainNavigationColorTabOther;
            font: configs.$mainNavigationFontTabTabletMobileOther;
            padding: map.get(configs.$spacing, s-8) map.get(configs.$spacing, s-12);
            text-decoration: none;

            @include mixins.breakpoint-from(desktop-small) {
                font: configs.$mainNavigationFontTabDesktopOther;
            }

            &--active {
                color: configs.$mainNavigationColorTabActive;
                font: configs.$mainNavigationFontTabTabletMobileActive;

                @include mixins.breakpoint-from(desktop-small) {
                    font: configs.$mainNavigationFontTabDesktopActive;
                }
            }

            &:hover,
            &:focus {
                color: configs.$mainNavigationColorTabHoverFocus;
            }

            &:focus-visible {
                outline: 2px solid configs.$colorBorderOutline;
                outline-offset: 4px;
            }

            .cmp-link__screen-reader-only {
                @include mixins.visually-hidden;
            }
        }
    }

    .top-menu {
        background: configs.$mainNavigationColorBackgroundTop;
        box-shadow: configs.$underBlue40a;
        padding: map.get(configs.$spacing, s-12) map.get(configs.$spacing, s-16);

        @include mixins.breakpoint-from(tablet) {
            padding: map.get(configs.$spacing, s-16);
        }

        &__content {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        &__brand {
            color: configs.$mainNavigationColorLogoDefault;
            display: inline-block;
            height: map.get(configs.$spacing, s-48);

            &::before {
                background-color: currentcolor;
                content: '';
                display: inline-block;
                height: map.get(configs.$spacing, s-24);
                margin-block-start: map.get(configs.$spacing, s-12);
                mask-image: var(--icon-logo);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                width: utilities.px-to-rem(125);

                @include mixins.breakpoint-from(tablet) {
                    height: utilities.px-to-rem(32);
                    margin-block-start: map.get(configs.$spacing, s-8);
                    width: utilities.px-to-rem(166);
                }
            }

            &:hover,
            &:focus {
                color: configs.$mainNavigationColorLogoHoverFocus;
            }

            &:focus-visible {
                outline: 2px solid configs.$colorBorderOutline;
            }
        }

        &__buttons {
            .button {
                .cmp-button {
                    --button-font: #{configs.$mainNavigationFontButton};
                    --button-spacing-top: 0;
                }
            }
        }

        .mobile-menu {
            color: configs.$mainNavigationColorIconDefault;
            height: map.get(configs.$spacing, s-48);
            width: map.get(configs.$spacing, s-48);

            &:active,
            &:hover {
                color: configs.$mainNavigationColorIconHoverFocus;
            }

            &:focus-visible {
                outline: 2px solid configs.$colorBorderOutline;
                outline-offset: 4px;
            }

            &--hamburger,
            &--close {
                &::before {
                    background-color: currentcolor;
                    content: '';
                    display: inline-block;
                    height: utilities.px-to-rem(25);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    vertical-align: middle;
                    width: utilities.px-to-rem(25);
                }
            }

            &--hamburger {
                &::before {
                    mask-image: var(--icon-menu);
                }
            }

            &--close {
                &::before {
                    mask-image: var(--icon-cross);
                }
            }
        }
    }

    .main-menu {
        &__item-link {
            text-decoration: none;

            &:focus-visible {
                outline: 2px solid configs.$colorBorderOutline;
            }
        }
    }

    &--mobile {
        height: 100%;
        max-height: 100vh;
        max-width: 100%;
        width: 100%;

        &[open] {
            display: flex;
            flex-direction: column;
        }

        &::backdrop {
            background-color: configs.$mainNavigationColorBackgroundOverlay;
        }

        .top-menu {
            &__buttons {
                border-bottom: 1px solid configs.$mainNavigationColorBorderDefault;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: map.get(configs.$spacing, s-24);
                margin-inline: map.get(configs.$spacing, s-32);
                padding-block: map.get(configs.$spacing, s-32);
            }
        }

        .main-menu {
            margin-block-end: auto;
            padding: map.get(configs.$spacing, s-32) map.get(configs.$spacing, s-24);

            &__item:not(:last-of-type) {
                padding-block-end: map.get(configs.$spacing, s-24);
            }

            &__item-link {
                color: configs.$dropdownColorTextDefault;
                font: configs.$dropdownFontMobileTitle;

                @include mixins.breakpoint-from(tablet) {
                    font: configs.$dropdownFontDesktopTitle;
                }

                &:hover,
                &:focus {
                    color: configs.$dropdownColorTextHover;
                }
            }

        }

        .segments-bar {
            align-self: flex-end;
            background: configs.$mainNavigationColorTabBackground;
            border-top: 1px solid configs.$mainNavigationColorBorderDefault;
            padding-inline: map.get(configs.$spacing, s-16);
            width: 100%;

            &__menu {
                padding-block: map.get(configs.$spacing, s-24);
            }

            &__menu-item:last-of-type {
                &::before {
                    background-color: configs.$mainNavigationColorIconDefault;
                    border-radius: 50%;
                    content: '';
                    display: inline-block;
                    height: 6px;
                    vertical-align: middle;
                    width: 6px;
                }
            }
        }
    }

    &--desktop {
        .segments-bar {
            display: none;

            @include mixins.breakpoint-from(desktop-small) {
                background-color: configs.$mainNavigationColorTabBackground;
                box-shadow: configs.$underBlue40a;
                display: grid;
                padding-block: map.get(configs.$spacing, s-8);

                @include mixins.full-width;
            }

            &__menu {
                flex-direction: row;

                @include mixins.breakpoint-from(desktop-small) {
                    margin-inline-start: 0;
                }

                @include mixins.breakpoint-from(desktop-large) {
                    margin-inline: var(--main-navigation-spacing-inline);
                }
            }

            &__link {
                border-bottom: 1px solid configs.$mainNavigationColorTabOther;

                &--active {
                    border-bottom: 1px solid configs.$mainNavigationColorTabActive;
                }

                &:hover,
                &:focus {
                    border-bottom-color: configs.$mainNavigationColorTabHoverFocus;
                }
            }
        }

        .top-menu {
            &__content {
                flex-direction: row;

                @include mixins.breakpoint-from(desktop-small) {
                    margin-inline-start: 0;
                }

                @include mixins.breakpoint-from(desktop-large) {
                    margin-inline: var(--main-navigation-spacing-inline);
                }
            }

            &__right-container {
                align-items: center;
                display: flex;
                flex-direction: row;
            }

            &__buttons {
                display: none;

                @include mixins.breakpoint-from(desktop-small) {
                    display: inline-block;
                }

                .button {
                    display: inline;
                    padding-inline-start: map.get(configs.$spacing, s-24);
                }
            }

            &__mobile {
                @include mixins.breakpoint-from(desktop-small) {
                    display: none;
                }
            }
        }

        .main-menu {
            display: none;

            @include mixins.breakpoint-from(desktop-small) {
                box-shadow: configs.$underBlue40a;
                display: block;
                padding-block: map.get(configs.$spacing, s-32);
            }

            &__menu {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            &__item-link {
                color: configs.$mainNavigationColorTextNotActive;
                font: configs.$mainNavigationFontItem;
                padding-block: map.get(configs.$spacing, s-32);
                padding-inline: map.get(configs.$spacing, s-12);

                &:hover,
                &:focus {
                    color: configs.$mainNavigationColorTextDefault;
                }

                &--active {
                    color: configs.$mainNavigationColorTextDefault;
                }
            }
        }
    }
}
