@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.cmp-header {
    &__container {
        @include mixins.breakpoint-from(desktop-small) {
            height: utilities.px-to-rem(617);
            position: relative;
        }
    }

    &__content {
        background: configs.$headerColorTabletMobileBackground;
        box-shadow: 0 2px 8px 0 rgba(0, 36, 74, 40%);
        color: configs.$headerColorText;
        margin-inline: calc(50% - 50vw);
        padding: map.get(configs.$spacing, s-24);

        @include mixins.breakpoint-from(tablet) {
            padding: map.get(configs.$spacing, s-32);
        }

        @include mixins.breakpoint-from(desktop-small) {
            background: configs.$headerColorDesktopBackground;
            inset-block-end: 0;
            margin-inline-start: 0;
            max-width: utilities.px-to-rem(747);
            min-width: utilities.px-to-rem(640);
            position: absolute;
            z-index: 1;
        }

        @include mixins.breakpoint-from(desktop-large) {
            margin-inline-start: var(--main-navigation-spacing-inline);
        }

        .cmp-title__text,
        .cmp-text {
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }

        .cmp-title {
            --title-text-color: #{configs.$headerColorText};
            --title-font-desktop-h1: #{configs.$headerFontDesktopTitle};
            --title-font-mobile-h1: #{configs.$headerFontMobileTitle};

            &__text {
                -webkit-line-clamp: 3;
                margin-block-end: map.get(configs.$spacing, s-12);

                @include mixins.breakpoint-from(tablet) {
                    -webkit-line-clamp: 4;
                }

                @include mixins.breakpoint-from(desktop-small) {
                    -webkit-line-clamp: 6;
                    max-height: utilities.px-to-rem(198);
                }
            }
        }

        .cmp-text {
            --text-color: #{configs.$headerColorText};
            --text-font-mobile: #{configs.$headerFontMobileDescription};
            --text-font-desktop: #{configs.$headerFontDesktopDescription};

            -webkit-line-clamp: 6;
            margin-block-end: map.get(configs.$spacing, s-24);
            padding: 0;

            @include mixins.breakpoint-from(tablet) {
                -webkit-line-clamp: 4;
                margin-block-end: map.get(configs.$spacing, s-32);
            }

            @include mixins.breakpoint-from(desktop-small) {
                -webkit-line-clamp: 2;
                max-height: utilities.px-to-rem(72);
            }
        }
    }

    &__image {
        max-width: unset;

        .cmp-image {
            background-color: configs.$imageColorBackup;

            &__image {
                aspect-ratio: map.get(configs.$aspect-ratios, '3-2');
                display: block;
                height: 100%;
                object-fit: cover;
                width: 100%;

                @include mixins.breakpoint-from(tablet) {
                    aspect-ratio: map.get(configs.$aspect-ratios, '16-9');
                }

                @include mixins.breakpoint-from(desktop-small) {
                    aspect-ratio: map.get(configs.$aspect-ratios, '21-9');
                    height: utilities.px-to-rem(617);
                }
            }
        }
    }

    &__button-container {
        @include mixins.breakpoint-from(tablet) {
            column-gap: map.get(configs.$spacing, s-24);
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__button {
        @include mixins.breakpoint-from(tablet) {
            max-width: fit-content;
            width: 100%;
        }
    }
}
