@use 'configs';
@use 'utilities';
@use 'mixins';
@use 'sass:map';

.footer {
    background-color: configs.$footerColorBackground;
    color: configs.$footerColorTextDefault;
    padding-block: map.get(configs.$spacing, s-32);

    @include mixins.breakpoint-from(tablet) {
        padding-block: map.get(configs.$spacing, s-40);
    }

    @include mixins.breakpoint-from(desktop-small) {
        padding-block: map.get(configs.$spacing, s-80);
    }

    .cmp-footer {
        &__content {
            display: grid;
            gap: map.get(configs.$spacing, s-32);
            grid-template-areas:
                    'social'
                    'utility'
                    'contact';
            grid-template-columns: 1fr;

            @include mixins.breakpoint-from(tablet) {
                grid-template-areas:
                        'social social'
                        'utility contact';
                grid-template-columns: repeat(2, 1fr);
            }

            @include mixins.breakpoint-from(desktop-small) {
                grid-template-areas: 'utility contact social';
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &__column {
            &--utilties {
                grid-area: utility;
            }

            &--contact {
                grid-area: contact;
            }

            &--social {
                grid-area: social;
                padding-block-end: map.get(configs.$spacing, s-32);
            }

            .cmp-title {
                --title-text-color: #{configs.$footerColorTextDefault};
                --title-font-desktop-h2: #{configs.$footerFontDesktopTitle};
                --title-font-mobile-h2: #{configs.$footerFontMobileTitle};

                margin-block-end: map.get(configs.$spacing, s-16);

                @include mixins.breakpoint-from(tablet) {
                    margin-block-end: map.get(configs.$spacing, s-32);
                }
            }

            .cmp-button {
                --button-bg-color: transparant;
                --button-bg-color-hover: transparant;
                --button-border-color: transparant;
                --button-border-color-hover: transparant;
                --button-font: #{configs.$footerFontDefault};
                --button-text-color: #{configs.$footerColorTextDefault};
                --button-text-color-hover: #{configs.$footerColorTextHover};
                --button-border-radius: 0;
                --button-spacing-top: 0;

                &__text {
                    padding: 0;
                    text-decoration: underline;

                    &:hover,
                    &:focus {
                        text-decoration: 2px underline;
                    }
                }
            }

            .cmp-text {
                --text-color: #{configs.$footerColorTextDefault};
                --text-link-color: #{configs.$footerColorTextHover};
                --text-font-mobile: #{configs.$footerFontDefault};
                --text-font-desktop: #{configs.$footerFontDefault};

                b {
                    font: configs.$footerFontBold;

                    @include mixins.breakpoint-from(tablet) {
                        font: configs.$footerFontBold;
                    }
                }
            }
        }

        &__utility-links {
            border-bottom: 1px solid configs.$footerColorTextDefault;
            padding-block-end: map.get(configs.$spacing, s-24);

            @include mixins.breakpoint-from(tablet) {
                border-bottom: none;
                border-right: 1px solid configs.$footerColorTextDefault;
                padding-block-end: 0;
            }
        }

        &__brand-logo {
            color: configs.$footerColorLogoDefault;
            margin-block-end: map.get(configs.$spacing, s-16);

            &::before {
                background-color: currentcolor;
                content: '';
                display: inline-block;
                height: utilities.px-to-rem(45);
                mask-image: var(--icon-logo);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                width: utilities.px-to-rem(234);
            }
        }

        &__brand-tagline {
            color: configs.$footerColorTextDescription;
            font: configs.$footerFontDefault;
            margin-block-end: map.get(configs.$spacing, s-32);

            @include mixins.breakpoint-from(desktop-small) {
                margin-block-end: map.get(configs.$spacing, s-80);
            }
        }

        &__social-media-title {
            color: configs.$footerColorTextDefault;
            font: configs.$footerFontSubTitle;
            margin-block-end: map.get(configs.$spacing, s-16);
        }

        &__social-links {
            > .cmp-container {
                display: flex;
                flex-direction: row;
                gap: map.get(configs.$spacing, s-8);
            }
        }

        .social-link {
            .cmp-button {
                color: configs.$footerColorIconDefault;

                &::before {
                    background-color: currentcolor;
                    content: '';
                    display: inline-block;
                    height: utilities.px-to-rem(20);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    width: utilities.px-to-rem(20);
                }

                &:hover,
                &:focus {
                    color: configs.$mainNavigationColorLogoHoverFocus;
                }

                &:focus-visible {
                    outline: 2px solid configs.$colorBorderOutline;

                }

                &__text {
                    @include mixins.visually-hidden;
                }
            }


            &--facebook {
                .cmp-button {
                    &::before {
                        mask-image: var(--icon-facebook);
                    }
                }
            }

            &--instagram {
                .cmp-button {
                    &::before {
                        mask-image: var(--icon-instagram);
                    }
                }
            }

            &--linked-in {
                .cmp-button {
                    &::before {
                        mask-image: var(--icon-linkedin);
                    }
                }
            }

            &--pinterest {
                .cmp-button {
                    &::before {
                        mask-image: var(--icon-pinterest);
                    }
                }
            }

            &--x {
                .cmp-button {
                    &::before {
                        mask-image: var(--icon-x-social);
                    }
                }
            }

            &--youtube {
                .cmp-button {
                    &::before {
                        mask-image: var(--icon-youtube);
                    }
                }
            }
        }
    }
}
