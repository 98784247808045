
// Do not edit directly
// Generated on Fri, 17 Jan 2025 09:37:15 GMT

$embedColorBackground: #00244a;
$embedColorText: #ffffff;
$embedColorArrow: #ffffff;
$embedColorVideoYoutube: #f00;
$embedColorVideoVimeo: #17d5ff;
$embedFontDesktopText: 400 18px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
$embedFontMobileText: 400 16px/1.5 'Open Sans', Arial, Helvetica, sans-serif;
