@use 'sass:map';
@use 'configs';
@use 'utilities';

.form-stepper {
    --bg-color: #{configs.$stepperColorBackgroundFutur};
    --border-color: var(--font-color);
    --font-color: #{configs.$stepperColorInfoFutur};

    &__step-list {
        display: flex;
        justify-content: stretch;
    }

    &__step {
        color: var(--font-color);
        display: flex;
        flex: 1 0 auto;
        font: configs.$stepperFontInfo;

        &:last-child {
            flex-grow: 0;
        }

        .step {
            display: flex;

            &__item {
                align-items: center;
                background: var(--bg-color);
                border: 2px solid var(--border-color);
                border-radius: 50%;
                display: flex;
                height: map.get(configs.$spacing, s-64);
                justify-content: center;
                text-align: center;
                width: map.get(configs.$spacing, s-64);
            }

            &__item-finish {
                &::after {
                    background-color: var(--font-color);
                    content: '';
                    display: inline-block;
                    height: utilities.px-to-rem(38);
                    mask-image: var(--icon-flags);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    transition: mask 150ms ease-in-out;
                    vertical-align: text-bottom;
                    width: utilities.px-to-rem(38);
                }
            }
        }

        &:not(:last-child)::after {
            background-color: configs.$stepperColorSeperator;
            content: '';
            display: block;
            flex: 1 0 auto;
            height: 1px;
            margin: auto map.get(configs.$spacing, s-16);
        }

        &--active {
            --font-color: #{configs.$stepperColorInfoCurrent};
            --bg-color: #{configs.$stepperColorBackgroundCurrent};
            --border-color: var(--bg-color);
        }

        &--complete {
            --font-color: #{configs.$stepperColorInfoFinished};
            --bg-color: #{configs.$stepperColorBackgroundFinished};

            .step {
                &__item {
                    &::after {
                        background-color: var(--font-color);
                        content: '';
                        display: inline-block;
                        height: utilities.px-to-rem(38);
                        mask-image: var(--icon-check);
                        mask-position: center;
                        mask-repeat: no-repeat;
                        mask-size: contain;
                        transition: mask 150ms ease-in-out;
                        vertical-align: text-bottom;
                        width: utilities.px-to-rem(38);
                    }
                }

                &__item-text {
                    display: none;
                }
            }
        }
    }

    &__pane-container {
        display: block;
        width: 100%;
    }

    &__pane {
        display: none;
        float: left;
        width: 100%;

        &--hidden {
            display: none;
        }

        &--active,
        &--initial {
            display: block;
        }

        &--initial.form-stepper__pane--hidden {
            display: none;
        }
    }
}
