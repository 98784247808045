@use 'configs';
@use 'sass:map';
@use 'utilities';

.skeleton {
    &__element {
        background-color: configs.$skeletonColorBackground;
        box-shadow: configs.$skeletonShadowBox;
        display: block;
        max-width: 100%;
        overflow: hidden;
        position: relative;

        &::after {
            animation: fadeInOut 1.2s ease-in-out infinite;
            background: configs.$skeletonColorBox;
            content: '';
            inset: 0;
            opacity: 1;
            position: absolute;
        }

        @keyframes fadeInOut {
            0% {
                opacity: 1;
            }

            50% {
                opacity: 0.5;
            }

            100% {
                opacity: 1;
            }
        }
    }

    &__title {
        height: map.get(configs.$spacing, s-40);
        margin-block: map.get(configs.$spacing, s-12) map.get(configs.$spacing, s-32);
        width: utilities.px-to-rem(500);
    }

    &__text {
        height: map.get(configs.$spacing, s-16);
        margin-block: map.get(configs.$spacing, s-12) map.get(configs.$spacing, s-8);
        width: utilities.px-to-rem(350);
    }

    &__radio {
        border-radius: utilities.px-to-rem(22);
        display: inline-block;
        height: utilities.px-to-rem(22);
        margin-block-start: map.get(configs.$spacing, s-4);
        width: utilities.px-to-rem(22);
    }

    &__radio-label {
        display: inline-block;
        height: map.get(configs.$spacing, s-16);
        margin: map.get(configs.$spacing, s-8) map.get(configs.$spacing, s-32)
                0 map.get(configs.$spacing, s-8);
        width: utilities.px-to-rem(200);
    }

    &__element-wrapper {
        &--inline {
            display: inline-block;
        }
    }

    &__group {
        margin-block-end: map.get(configs.$spacing, s-40);
    }

    &--hidden {
        display: none;
    }
}
