@use 'configs';
@use 'breakpoint-mixins';

.cmp-title {
    // use these custom properties for styling titles in composites
    --title-text-color: #{configs.$titleColorDefault};
    --title-font-desktop-h1: #{configs.$titleFontDesktopH1};
    --title-font-mobile-h1: #{configs.$titleFontMobileH1};
    --title-font-desktop-h2: #{configs.$titleFontDesktopH2};
    --title-font-mobile-h2: #{configs.$titleFontMobileH2};
    --title-font-desktop-h3: #{configs.$titleFontDesktopH3};
    --title-font-mobile-h3: #{configs.$titleFontMobileH3};

    &__text {
        color: var(--title-text-color);
    }

    h1 {
        font: var(--title-font-mobile-h1);

        @include breakpoint-mixins.breakpoint-from(tablet) {
            font: var(--title-font-desktop-h1);
        }
    }

    h2 {
        font: var(--title-font-mobile-h2);

        @include breakpoint-mixins.breakpoint-from(tablet) {
            font: var(--title-font-desktop-h2);
        }
    }

    h3 {
        font: var(--title-font-mobile-h3);

        @include breakpoint-mixins.breakpoint-from(tablet) {
            font: var(--title-font-desktop-h3);
        }
    }

    h4 {
        font: configs.$titleFontMobileH4;

        @include breakpoint-mixins.breakpoint-from(tablet) {
            font: configs.$titleFontDesktopH4;
        }
    }

    h5 {
        font: configs.$titleFontMobileH5;

        @include breakpoint-mixins.breakpoint-from(tablet) {
            font: configs.$titleFontDesktopH5;
        }
    }

    h6 {
        font: configs.$titleFontMobileH6;

        @include breakpoint-mixins.breakpoint-from(tablet) {
            font: configs.$titleFontDesktopH6;
        }
    }
}
