@use 'a11y-mixins';
@use 'configs';
@use 'sass:map';
@use 'utilities';

[class*='--dark'] {
    .cmp-button {
        &--primary {
            --button-text-color: #{configs.$buttonColorTextPrimaryOnDark};
            --button-bg-color: #{configs.$buttonColorBackgroundPrimaryOnDark};
            --button-bg-color-hover: #{configs.$buttonColorBackgroundPrimaryHoverFocusOnDark};
        }

        &--secondary {
            --button-text-color: #{configs.$buttonColorTextSecondaryOnDark};
            --button-bg-color: #{configs.$buttonColorBackgroundSecondaryOnDark};
            --button-bg-color-hover: #{configs.$buttonColorBackgroundSecondaryHoverFocusOnDark};
            --button-border-color: #{configs.$buttonColorBorderSecondaryOnDark};
            --button-border-color-hover: #{configs.$buttonColorBorderSecondaryHoverFocusOnDark};
            --button-text-color-hover: #{configs.$buttonColorTextSecondaryHoverFocusOnDark};
        }

        &--tertiary {
            --button-text-color: #{configs.$buttonColorTextTertiaryOnDark};
            --button-text-color-hover: #{configs.$buttonColorTextTertiaryHoverFocusOnDark};
        }
    }
}

.cmp-button {
    --button-border-radius: #{utilities.px-to-rem(50)};
    --button-font: #{configs.$buttonFontDefault};
    --button-spacing-top: #{map.get(configs.$spacing, s-24)};

    &--primary {
        --button-text-color: #{configs.$buttonColorTextPrimaryOnLight};
        --button-bg-color: #{configs.$buttonColorBackgroundPrimaryOnLight};
        --button-bg-color-hover: #{configs.$buttonColorBackgroundPrimaryHoverFocusOnLight};
    }

    &--secondary {
        --button-text-color: #{configs.$buttonColorTextSecondaryOnLight};
        --button-bg-color: #{configs.$buttonColorBackgroundSecondaryOnLight};
        --button-bg-color-hover: #{configs.$buttonColorBackgroundSecondaryHoverFocusOnLight};
        --button-border-color: #{configs.$buttonColorBorderSecondaryOnLight};
        --button-border-color-hover: #{configs.$buttonColorBorderSecondaryHoverFocusOnLight};
        --button-text-color-hover: #{configs.$buttonColorTextSecondaryHoverFocusOnLight};

        .cmp-button {
            border: 1px solid var(--button-border-color);

            &:hover,
            &:focus-visible {
                --button-text-color: var(--button-text-color-hover);

                border-color: var(--button-border-color-hover);
            }
        }
    }

    &--tertiary {
        --button-bg-color: transparant;
        --button-bg-color-hover: transparant;
        --button-border-color: transparant;
        --button-border-color-hover: transparant;
        --button-text-color: #{configs.$buttonColorTextTertiaryOnLight};
        --button-text-color-hover: #{configs.$buttonColorTextTertiaryHoverFocusOnLight};
        --button-border-radius: 0;

        .cmp-button {
            --button-border-radius: 0;

            &:hover,
            &:focus-visible {
                --button-text-color: var(--button-text-color-hover);

                .cmp-button__text {
                    text-decoration: 1px underline;

                    &::after {
                        inset-inline-start: utilities.px-to-rem(6);
                    }
                }
            }

            &__text {
                padding: map.get(configs.$spacing, s-12) 0;
                transition: color 150ms ease-in-out;

                &::after {
                    background-color: currentcolor;
                    content: '';
                    display: inline-block;
                    height: utilities.px-to-rem(18);
                    inset-inline-start: map.get(configs.$spacing, s-4);
                    mask-image: var(--icon-chevron-right);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    position: relative;
                    transition: inset-inline-start 150ms ease-in-out;
                    vertical-align: middle;
                    width: utilities.px-to-rem(18);
                }
            }
        }
    }

    background-color: var(--button-bg-color);
    border-radius: var(--button-border-radius);
    display: inline-block;
    font: var(--button-font);
    margin-block-start: var(--button-spacing-top);
    max-width: 100%;
    text-decoration: none;

    &__text {
        color: var(--button-text-color);
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        padding: map.get(configs.$spacing, s-12) map.get(configs.$spacing, s-24);
        text-align: left;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
    }

    &:hover:not(:disabled),
    &:focus-visible {
        background-color: var(--button-bg-color-hover);
        transition: background-color 150ms ease-in-out;
    }

    &:focus-visible {
        outline: 2px solid configs.$colorBorderOutline;
        outline-offset: 2px;
    }

    &:disabled {
        cursor: default;
        opacity: 0.2;
    }

    .cmp-link__screen-reader-only {
        @include a11y-mixins.visually-hidden;
    }
}
