@use 'sass:map';
@use 'sass:string';

/**
 * Returns URL-escaped inline SVG code.
 */
@function svg-code-escape($svg-code) {
    // Characters to escape from SVGs.
    $svg-icons-escape-chars: (
            ' ': '%20',
            '\'': '%22',
            '"': '%27',
            '#': '%23',
            '/': '%2F',
            ':': '%3A',
            '(': '%28',
            ')': '%29',
            '%': '%25',
            '<': '%3C',
            '>': '%3E',
            '\\': '%5C',
            '^': '%5E',
            '{': '%7B',
            '|': '%7C',
            '}': '%7D',
    );
    $escaped-string: '';
    $unquote-icon: string.unquote($svg-code);

    // Loop through each character in string.
    @for $i from 1 through str-length($unquote-icon) {
        $char: string.slice($unquote-icon, $i, $i);

        // Check if character is in symbol map.
        $char-lookup: map.get($svg-icons-escape-chars, $char);

        // If it is, use escaped version.
        @if $char-lookup {
            $char: $char-lookup;
        }

        // Append character to escaped string.
        $escaped-string: $escaped-string + $char;
    }

    // Return inline SVG data.
    @return url('data:image/svg+xml, #{$escaped-string}');
}
