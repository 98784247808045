@use 'sass:map';
@use 'configs';
@use 'mixins';

.card {
    --card-background-color: #{configs.$cardColorBackgroundsDefault};
    --card-box-shadow: #{configs.$centerBlue10a};
    --card-border-color: #{configs.$cardColorBorder};
    --card-border-radius: 4px;
    --card-button-margin-block: auto 0;
    --card-title-margin-end-desktop: #{map.get(configs.$spacing, s-12)};
    --card-title-margin-end-mobile: #{map.get(configs.$spacing, s-12)};
    --card-spacing-bottom-desktop: #{map.get(configs.$spacing, s-40)};
    --card-spacing-bottom-mobile: #{map.get(configs.$spacing, s-24)};
    --card-spacing-bottom-tablet: #{map.get(configs.$spacing, s-32)};
    --card-spacing-image-desktop: #{map.get(configs.$spacing, s-40)};
    --card-spacing-image-mobile: #{map.get(configs.$spacing, s-24)};
    --card-spacing-image-tablet: #{map.get(configs.$spacing, s-32)};
    --card-spacing-inline-desktop: #{map.get(configs.$spacing, s-40)};
    --card-spacing-inline-mobile: #{map.get(configs.$spacing, s-24)};
    --card-spacing-inline-tablet: #{map.get(configs.$spacing, s-32)};
    --card-visual-aspect-ratio: #{map.get(configs.$aspect-ratios, '16-9')};

    background-color: var(--card-background-color);
    border: 1px solid var(--card-border-color);
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);

    > *:first-child {
        display: flex;
        flex-direction: column;
        height: 100%;

        > *:last-child {
            margin-block-end: var(--card-spacing-bottom-mobile);

            @include mixins.breakpoint-from(tablet) {
                margin-block-end: var(--card-spacing-bottom-tablet);
            }

            @include mixins.breakpoint-from(desktop-small) {
                margin-block-end: var(--card-spacing-bottom-desktop);
            }
        }
    }

    &__visual {
        aspect-ratio: var(--card-visual-aspect-ratio);
        background-color: configs.$imageColorBackup;
        border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
        margin-block-end: var(--card-spacing-image-mobile);

        @include mixins.breakpoint-from(tablet) {
            margin-block-end: var(--card-spacing-image-tablet);
        }

        @include mixins.breakpoint-from(desktop-small) {
            margin-block-end: var(--card-spacing-image-desktop);
        }

        .cmp-image__image {
            aspect-ratio: var(--card-visual-aspect-ratio);
            background-color: configs.$imageColorBackground;
            border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
            display: block;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    &__title {
        margin-block-end: var(--card-title-margin-end-mobile);
        margin-inline: var(--card-spacing-inline-mobile);

        @include mixins.breakpoint-from(tablet) {
            margin-block-end: var(--card-title-margin-end-desktop);
            margin-inline: var(--card-spacing-inline-tablet);
        }

        @include mixins.breakpoint-from(desktop-small) {
            margin-inline: var(--card-spacing-inline-desktop);
        }

        .cmp-title {
            --title-font-desktop-h3: #{configs.$cardFontDesktopTitle};
            --title-font-mobile-h3: #{configs.$cardFontMobileTitle};
            --title-text-color: #{configs.$cardColorTextTitle};
        }
    }

    &__description {
        margin-inline: var(--card-spacing-inline-mobile);

        @include mixins.breakpoint-from(tablet) {
            margin-inline: var(--card-spacing-inline-tablet);
        }

        @include mixins.breakpoint-from(desktop-small) {
            margin-inline: var(--card-spacing-inline-desktop);
        }

        .cmp-text {
            --text-color: #{configs.$cardColorTextRunningText};
            --text-list-marker: #{configs.$cardColorTextBullets};
            --text-font-mobile: #{configs.$cardFontMobileDescription};
            --text-font-desktop: #{configs.$cardFontDesktopDescription};

            > ul,
            > ol {
                padding-inline: 0;
            }
        }
    }

    &__cta {
        margin-block: var(--card-button-margin-block);
        margin-inline: var(--card-spacing-inline-mobile);

        @include mixins.breakpoint-from(tablet) {
            margin-inline: var(--card-spacing-inline-tablet);
        }

        @include mixins.breakpoint-from(desktop-small) {
            margin-inline: var(--card-spacing-inline-desktop);
        }
    }

    &--carousel {
        --card-background-color: transparent;
        --card-border-color: transparent;
        --card-box-shadow: none;
        --card-spacing-bottom-desktop: 0;
        --card-spacing-bottom-mobile: 0;
        --card-spacing-bottom-tablet: 0;
        --card-spacing-image-desktop: #{map.get(configs.$spacing, s-32)};
        --card-spacing-image-mobile: #{map.get(configs.$spacing, s-24)};
        --card-spacing-image-tablet: #{map.get(configs.$spacing, s-32)};
        --card-spacing-inline-desktop: 0;
        --card-spacing-inline-mobile: 0;
        --card-spacing-inline-tablet: 0;
        --card-title-margin-end-mobile: #{map.get(configs.$spacing, s-8)};
        --card-visual-aspect-ratio: #{map.get(configs.$aspect-ratios, '1-1')};

        @include mixins.breakpoint-from(desktop-small) {
            --card-visual-aspect-ratio: #{map.get(configs.$aspect-ratios, '4-3')};
        }

        .cmp-image__image {
            aspect-ratio: var(--card-visual-aspect-ratio);

            @include mixins.breakpoint-from(desktop-small) {
                --card-visual-aspect-ratio: #{map.get(configs.$aspect-ratios, '4-3')};
            }
        }
    }

    &--inspiration {
        --card-background-color: #{configs.$cardColorBackgroundsDefault};
        --card-button-margin-block: auto;
        --card-spacing-bottom-desktop: #{map.get(configs.$spacing, s-40)};
        --card-spacing-bottom-mobile: #{map.get(configs.$spacing, s-24)};
        --card-spacing-bottom-tablet: #{map.get(configs.$spacing, s-32)};
        --card-visual-aspect-ratio: #{map.get(configs.$aspect-ratios, '16-9')};
        --card-spacing-image-desktop: 0;
        --card-spacing-image-mobile: 0;
        --card-spacing-image-tablet: 0;

        .control-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }

        .cmp-button {
            --button-spacing-top: 0;
        }

        .carousel__controls {
            margin-inline: var(--card-spacing-inline-mobile);
            padding-block: map.get(configs.$spacing, s-16);

            @include mixins.breakpoint-from(tablet) {
                margin-inline: var(--card-spacing-inline-tablet);
            }

            @include mixins.breakpoint-from(desktop-small) {
                margin-inline: var(--card-spacing-inline-desktop);
            }
        }
    }
}
