@use 'mixins';
@use 'configs';

.background {
    &--color-default,
    &--color-themed {
        --container-background-color: transparent;

        @include mixins.full-width;

        background-color: var(--container-background-color);
    }

    &--color-default {
        --container-background-color: #{configs.$containerColorDefault};
    }

    &--color-themed {
        --container-background-color: #{configs.$containerColorTheme};
    }
}
