@use 'configs';
@use 'utilities';
@use 'mixins';
@use 'sass:map';

.cmp-language-navigation {
    padding-inline-start: map.get(configs.$spacing, s-32);
    position: relative;

    @include mixins.breakpoint-from(desktop-small) {
        align-self: stretch;
        display: inline-flex;
    }

    &__dialog {
        background-color: configs.$languageSelectorColorBackground;
        border: 1px solid configs.$languageSelectorColorBorder;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top: 0;
        box-shadow: configs.$underBlue40a;
        left: auto;
        overflow: visible;
        padding-block: map.get(configs.$spacing, s-16);
        position: absolute;
        right: 0;
        top: utilities.px-to-rem(60);
        z-index: var(--zindex-language-navigation);

        @include mixins.breakpoint-from(tablet) {
            top: map.get(configs.$spacing, s-64);
        }
    }

    &__language {
        border: none;
        color: configs.$languageSelectorColorText;
        display: block;
        font: configs.$mainNavigationFontItem;
        padding: map.get(configs.$spacing, s-8) map.get(configs.$spacing, s-32);
        text-decoration: none;
        width: 100%;

        &:hover,
        &:focus-visible {
            text-decoration: underline;
        }

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
            outline-offset: -2px;
        }

        &--active {
            align-items: center;
            display: flex;

            &:hover,
            &:focus-visible {
                text-decoration: none;
            }
        }

        &--icon {
            background-color: configs.$languageSelectorColorText;
            border-radius: 50%;
            box-sizing: content-box;
            display: inline-block;
            height: map.get(configs.$spacing, s-16);
            margin-right: map.get(configs.$spacing, s-16);
            padding: map.get(configs.$spacing, s-4);
            vertical-align: middle;
            width: map.get(configs.$spacing, s-16);

            &::before {
                background-color: configs.$languageSelectorColorSelectedIcon;
                content: '';
                display: block;
                height: map.get(configs.$spacing, s-16);
                mask-image: var(--icon-globe);
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
                width: map.get(configs.$spacing, s-16);
            }
        }

        &--label {
            min-width: utilities.px-to-rem(100);
        }
    }

    &__trigger {
        color: configs.$buttonColorTextQuartenaryOnDark;
        height: map.get(configs.$spacing, s-48);
        min-width: map.get(configs.$spacing, s-48);

        @include mixins.breakpoint-from(desktop-small) {
            border-radius: map.get(configs.$spacing, s-4);
            height: utilities.px-to-rem(36);
            margin: utilities.px-to-rem(6);
            padding: utilities.px-to-rem(6);
        }

        &::before {
            background-color: configs.$buttonColorTextQuartenaryOnDark;
            content: '';
            display: inline-block;
            height: utilities.px-to-rem(25);
            mask-image: var(--icon-globe);
            mask-position: center;
            mask-repeat: no-repeat;
            mask-size: contain;
            vertical-align: middle;
            width: utilities.px-to-rem(25);
        }

        @include mixins.breakpoint-from(desktop-small) {
            &:hover,
            &:focus-visible {
                background-color: configs.$buttonColorBackgroundQuartenaryHoverFocusOnDark;
                color: configs.$buttonColorTextQuartenaryOnLight;

                &::before {
                    background-color: configs.$buttonColorTextQuartenaryOnLight;
                }
            }
        }

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
            outline-offset: 4px;

            @include mixins.breakpoint-from(desktop-small) {
                outline-offset: 2px;
            }
        }
    }

    &__trigger-text {
        display: none;

        @include mixins.breakpoint-from(desktop-small) {
            border: none;
            display: inline-block;
            font: configs.$buttonFontDefault;
            padding-inline-start: map.get(configs.$spacing, s-4);
            text-decoration: none;
            text-transform: uppercase;
            vertical-align: middle;
        }
    }

    &__trigger-description {
        @include mixins.visually-hidden;

        @include mixins.breakpoint-from(desktop-small) {
            display: none;
        }
    }
}

.top-menu__buttons + .cmp-language-navigation {
    @include mixins.breakpoint-from(desktop-small) {
        border-left: 1px solid configs.$languageSelectorColorBorder;
        margin-inline-start: map.get(configs.$spacing, s-32);
        padding-inline-start: map.get(configs.$spacing, s-12);
    }
}
