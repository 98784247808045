@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

:root {
    --content-max-width: #{utilities.px-to-rem(1200)};
    --grid-offset: #{map.get(configs.$spacing, s-24)};
    --main-navigation-spacing-inline: -#{map.get(configs.$spacing, s-64)};
    --text-readable-width: #{utilities.px-to-rem(790)};
}

body {
    overflow-x: hidden;
}

main {
    @include mixins.content-container;

    // Highlight component above the footer should not have margin-bottom
    #main-content {
        > .highlight:last-child {
            .cmp-highlight {
                margin-block-end: 0;
            }
        }
    }

    .container-content,
    .container-secondary-navigation {
        > .cmp-container {
            > .text + .title,
            > .embed + .title {
                margin-block-start: map.get(configs.$spacing, s-48);

                @include mixins.breakpoint-from(tablet) {
                    margin-block-start: map.get(configs.$spacing, s-64);
                }

                @include mixins.breakpoint-from(desktop-small) {
                    margin-block-start: map.get(configs.$spacing, s-80);
                }
            }

            > .title + .text,
            > .title + .embed,
            > .text + .embed {
                margin-block-start: map.get(configs.$spacing, s-8);

                @include mixins.breakpoint-from(tablet) {
                    margin-block-start: map.get(configs.$spacing, s-12);
                }
            }
        }
    }

    .container-content {
        &--long-read {
            margin-block: map.get(configs.$spacing, s-48);

            @include mixins.breakpoint-from(tablet) {
                margin-block: map.get(configs.$spacing, s-64);
            }

            @include mixins.breakpoint-from(desktop-small) {
                margin: map.get(configs.$spacing, s-80) auto;
                max-width: var(--text-readable-width);
            }

            > .cmp-container {
                > .embed + .download-link,
                > .image + .download-link,
                > .download-link + .image,
                > .download-link + .title,
                > .download-link + .embed {
                    margin-block-start: map.get(configs.$spacing, s-48);

                    @include mixins.breakpoint-from(tablet) {
                        margin-block-start: map.get(configs.$spacing, s-64);
                    }

                    @include mixins.breakpoint-from(desktop-small) {
                        margin-block-start: map.get(configs.$spacing, s-80);
                    }
                }

                > .title + .download-link {
                    margin-block-start: map.get(configs.$spacing, s-8);

                    @include mixins.breakpoint-from(tablet) {
                        margin-block-start: map.get(configs.$spacing, s-12);
                    }
                }

                > .download-link + .download-link {
                    margin-block-start: map.get(configs.$spacing, s-8);
                }

                > .text + .download-link,
                > .download-link + .text {
                    margin-block-start: map.get(configs.$spacing, s-24);
                }

                > .text + .title {
                    margin-block-start: map.get(configs.$spacing, s-24);

                    @include mixins.breakpoint-from(tablet) {
                        margin-block-start: map.get(configs.$spacing, s-32);
                    }
                }

                > .text + .image,
                > .image + .title,
                > .text + .embed {
                    margin-block-start: map.get(configs.$spacing, s-48);

                    @include mixins.breakpoint-from(tablet) {
                        margin-block-start: map.get(configs.$spacing, s-64);
                    }

                    @include mixins.breakpoint-from(desktop-small) {
                        margin-block-start: map.get(configs.$spacing, s-80);
                    }
                }
            }
        }

        &--long-read-themed {
            margin-block: 0;

            @include mixins.breakpoint-from(desktop-small) {
                margin-inline: calc(50% - 50vw);
                max-width: unset;
            }

            > .cmp-container {
                margin-inline: auto;

                .cmp-text {
                    ol,
                    ul {
                        --text-list-padding-start: 0;
                    }
                }

                @include mixins.breakpoint-from(desktop-small) {
                    max-width: var(--text-readable-width);
                }
            }
        }
    }
}

// breakout container for components that need full width
// WITH its content within main container width
// WITH full-width for ultra wide screens
// e.g. main nav, header, themable container, footer,..
.full-width {
    @include mixins.full-width;
}

// breakout container for components that need full width
// WITHOUT it's content constraint to the main content container
// WITH constraint to 1440px width on ultrawide screens
// e.g. highlight component, carousel,...
.container-bleed {
    margin-inline: calc(50% - 50vw);
    max-width: utilities.px-to-rem(1440);
    width: auto;

    @include mixins.breakpoint-from(desktop-large) {
        transform: translateX(calc(50vw - 50%));
    }
}
