
// Do not edit directly
// Generated on Fri, 17 Jan 2025 09:37:15 GMT

$controlsColorIcon: #00244a;
$controlsColorHoverFocus: #005ca9;
$controlsColorBorder: #cad7e3;
$controlsColorIconReversed: #ffffff;
$controlsColorBackground: #ffffff;
$controlsColorGradient: linear-gradient(90deg, #FFFFFF00 00 0%, #FFFFFF 100%);
