@use 'sass:math';

/*
 * Usage:
 *
 * font-size: px-to-rem(30);
 */
@function px-to-rem($pixels, $context: 16) {
    @return #{math.div($pixels, $context)}rem;
}
