@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.cmp-secondary-navigation {
    --secondary-navigation-grid-gap: #{map.get(configs.$spacing, s-32)};

    margin-block-end: map.get(configs.$spacing, s-48);

    @include mixins.breakpoint-from(tablet) {
        margin-block-end: map.get(configs.$spacing, s-64);
    }

    @include mixins.breakpoint-from(desktop-small) {
        display: grid;
        gap: var(--secondary-navigation-grid-gap);
        grid-template-columns: 1fr repeat(12, minmax(0, calc(#{var(--content-max-width)} / 12))) 1fr;
        margin-block-end: map.get(configs.$spacing, s-80);
    }

    &__menu {
        background: configs.$secondaryNavigationColorBackground;
        border: 1px solid configs.$secondaryNavigationColorBorder;
        border-radius: 4px;
        box-shadow: configs.$centerBlue10a;
        height: fit-content;
        margin-block-end: map.get(configs.$spacing, s-24);
        padding: map.get(configs.$spacing, s-24);

        @include mixins.breakpoint-from(tablet) {
            margin-block-end: map.get(configs.$spacing, s-32);
            padding: map.get(configs.$spacing, s-32);
        }

        @include mixins.breakpoint-from(desktop-small) {
            background: transparent;
            border: none;
            box-shadow: unset;
            grid-column: 1 / span 4;
            grid-row: 1 / -1;
            margin-block-end: 0;
            padding: map.get(configs.$spacing, s-24) map.get(configs.$spacing, s-24) map.get(configs.$spacing, s-24) 0;
            position: relative;

            &::before {
                background: configs.$secondaryNavigationColorBackground;
                border: 1px solid configs.$secondaryNavigationColorBorder;
                border-radius: 4px;
                box-shadow: configs.$centerBlue10a;
                content: '';
                inset: 0 0 0 -3vw;
                position: absolute;
                z-index: -1;
            }
        }

        @include mixins.breakpoint-from(desktop-large) {
            margin-inline-start: var(--main-navigation-spacing-inline);
        }

        .cmp-title {
            --title-text-color: #{configs.$secondaryNavigationColorTitle};
            --title-font-desktop-h2: #{configs.$secondaryNavigationFontDesktopTitle};
            --title-font-mobile-h2: #{configs.$secondaryNavigationFontMobileTitle};

            padding-block-end: map.get(configs.$spacing, s-16);
        }

        .cmp-list {
            &__item:not(:last-child) {
                padding-block-end: map.get(configs.$spacing, s-8);
            }

            &__item-link {
                color: configs.$secondaryNavigationColorLink;
                font: configs.$secondaryNavigationFontMobileLink;
                text-decoration: none;

                @include mixins.breakpoint-from(tablet) {
                    font: configs.$secondaryNavigationFontDesktopLink;
                }
            }
        }
    }

    &__content {
        @include mixins.breakpoint-from(desktop-small) {
            grid-column: 5 / span 9;
            grid-row: 1 / -1;
            margin-inline-end: calc(var(--secondary-navigation-grid-gap) * -1);

        }
    }
}
