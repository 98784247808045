@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.cookie-modal-reference {
    position: relative;
    z-index: var(--zindex-cookie-modal);
}

.cmp-cookie-modal {
    background-color: configs.$cookieColorBackground;
    border: 1px solid configs.$cookieColorBorder;
    bottom: 0;
    box-shadow: configs.$centerBlue40a;
    color: configs.$cookieColorText;
    margin-block: auto 0;
    max-width: initial;
    padding: map.get(configs.$spacing, s-24);
    position: fixed;
    width: 100%;

    @include mixins.breakpoint-from(tablet) {
        margin: auto map.get(configs.$spacing, s-24) 0 auto;
        max-width: utilities.px-to-rem(482);
        padding: map.get(configs.$spacing, s-40);
    }

    &__title {
        font: configs.$cookieFontMobileTitle;
        margin-block-end: map.get(configs.$spacing, s-8);

        @include mixins.breakpoint-from(tablet) {
            font: configs.$cookieFontDesktopTitle;
            margin-block-end: map.get(configs.$spacing, s-12);
        }
    }

    &__subtitle {
        font: configs.$cookieFontMobileSubtitle;
        margin-block-end: map.get(configs.$spacing, s-8);

        @include mixins.breakpoint-from(tablet) {
            font: configs.$cookieFontDesktopSubtitle;
        }
    }

    &__text {
        font: configs.$cookieFontMobileText;
        margin-block-end: map.get(configs.$spacing, s-16);

        @include mixins.breakpoint-from(tablet) {
            font: configs.$cookieFontDesktopText;
        }

        a {
            color: configs.$cookieColorText;
            text-decoration: 1px underline;
            transition: text-decoration 2s ease-in-out;

            &:hover,
            &:focus {
                color: configs.$cookieColorHoverFocus;
                text-decoration: 2px underline;
            }

            &:focus-visible {
                outline: 2px solid configs.$colorBorderOutline;
            }
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        gap: map.get(configs.$spacing, s-16);
        margin-block-start: map.get(configs.$spacing, s-24);

        @include mixins.breakpoint-from(tablet) {
            gap: map.get(configs.$spacing, s-24);
        }

        .cmp-button {
            --button-spacing-top: 0;
        }
    }

    &__cookies {
        list-style-type: disc;
        margin-block-end: map.get(configs.$spacing, s-16);
        margin-inline-start: map.get(configs.$spacing, s-16);
    }

    &__cookie {
        li::marker {
            color: configs.$cookieColorBullets;
        }

        .cookie {
            &__tooltip {
                font: configs.$cookieFontMobileText;

                @include mixins.breakpoint-from(tablet) {
                    font: configs.$cookieFontDesktopText;
                }

                &::after {
                    background-color: currentcolor;
                    content: '';
                    display: inline-block;
                    height: utilities.px-to-rem(24);
                    mask-image: var(--icon-info);
                    mask-position: center;
                    mask-repeat: no-repeat;
                    mask-size: contain;
                    position: relative;
                    vertical-align: bottom;
                    width: utilities.px-to-rem(24);
                }

                @include mixins.breakpoint-from(tablet) {
                    &::after {
                        background-color: unset;
                        content: ':';
                        display: inline-block;
                        height: auto;
                        inset-inline-start: 0;
                        mask-image: unset;
                        width: auto;
                    }
                }

                &:focus-visible {
                    border-radius: 50%;
                    outline: 2px solid configs.$colorBorderOutline;
                    outline-offset: 2px;
                }
            }

            &__text {
                display: inline-block;
                font: configs.$cookieFontMobileText;

                @include mixins.breakpoint-from(tablet) {
                    display: inline;
                    font: configs.$cookieFontDesktopText;
                }
            }

            &__title {
                font: configs.$cookieFontMobileText;

                @include mixins.breakpoint-from(tablet) {
                    font: configs.$cookieFontDesktopText;
                }
            }
        }
    }
}
