@use 'sass:map';
@use 'configs';
@use 'mixins';
@use 'utilities';

.cmp-text {
    // Use these custom properties for components with a text component
    --text-color: #{configs.$richTextColorTextOnLightDefault};
    --text-link-color: #{configs.$richTextColorTextOnLightLink};
    --text-list-marker: #{configs.$richTextColorTextOnLightSubText};
    --text-list-padding-start: #{map.get(configs.$spacing, s-16)};
    --text-font-mobile: #{configs.$richTextFontMobileDefault};
    --text-font-desktop: #{configs.$richTextFontDesktopDefault};

    color: var(--text-color);
    font: var(--text-font-mobile);
    max-width: var(--text-readable-width);

    @include mixins.breakpoint-from(tablet) {
        font: var(--text-font-desktop);
    }

    h3 {
        color: configs.$titleColorDefault;
        font: configs.$titleFontMobileH3;
        margin-block: map.get(configs.$spacing, s-24) map.get(configs.$spacing, s-8);

        @include mixins.breakpoint-from(tablet) {
            font: configs.$titleFontDesktopH3;
            margin-block: map.get(configs.$spacing, s-32) map.get(configs.$spacing, s-12);
        }

        &:first-child {
            margin-block-start: 0;
        }
    }

    b {
        font: configs.$richTextFontMobileBold;

        @include mixins.breakpoint-from(tablet) {
            font: configs.$richTextFontDesktopBold;
        }
    }

    a {
        color: var(--text-link-color);
        text-decoration: 1px underline;
        transition: text-decoration 2s ease-in-out;

        &:hover,
        &:focus {
            text-decoration: 2px underline;
        }

        &:focus-visible {
            outline: 2px solid configs.$colorBorderOutline;
        }
    }

    ul {
        list-style-position: inside;
        list-style-type: disc;
        padding-inline-start: var(--text-list-padding-start);

        li::marker {
            color: var(--text-list-marker);
        }

        ul {
            list-style-type: circle;

            ul {
                list-style-type: disc;

                ul {
                    list-style-type: circle;
                }
            }
        }
    }

    ol {
        list-style-position: inside;
        padding-inline-start: var(--text-list-padding-start);

        > li::before {
            color: var(--text-list-marker);
            content: counters(list-item, '.') '.';
            margin-inline-end: map.get(configs.$spacing, s-12);
        }
    }

    .cmp-link__screen-reader-only {
        @include mixins.visually-hidden;
    }
}
