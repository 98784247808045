@use 'sass:map';
@use 'configs';
@use 'mixins';

.themable-layout-container {
    // set default paddings for same containers that succeed each other
    &.background--color-themed,
    &.background--color-default {
        padding-block-end: map.get(configs.$spacing, s-48);

        @include mixins.breakpoint-from(tablet) {
            padding-block-end: map.get(configs.$spacing, s-64);
        }

        @include mixins.breakpoint-from(desktop-large) {
            padding: 0 map.get(configs.$spacing, s-120) map.get(configs.$spacing, s-80);
        }
    }

    // override padding-block-start for first container or first container after a different container
    &:first-of-type,
    &:not(.background--color-themed)+.background--color-themed,
    &:not(.background--color-default)+.background--color-default {
        padding-block-start: map.get(configs.$spacing, s-48);

        @include mixins.breakpoint-from(tablet) {
            padding-block-start: map.get(configs.$spacing, s-64);
        }

        @include mixins.breakpoint-from(desktop-large) {
            padding-block-start: map.get(configs.$spacing, s-80);
        }
    }

    // Spacing between allowed components inside themeable container
    > .cmp-container > *:not(:first-child) {
        margin-block-start: map.get(configs.$spacing, s-24);

        @include mixins.breakpoint-from(tablet) {
            margin-block-start: map.get(configs.$spacing, s-32);
        }
    }
}

// override padding block start when themable container is placed after another element or container which isn't a themable container
*:not(.background--color-themed)+.background--color-themed,
*:not(.background--color-default)+.background--color-default {
    padding-block-start: map.get(configs.$spacing, s-48);

    @include mixins.breakpoint-from(tablet) {
        padding-block-start: map.get(configs.$spacing, s-64);
    }

    @include mixins.breakpoint-from(desktop-large) {
        padding-block-start: map.get(configs.$spacing, s-80);
    }
}
